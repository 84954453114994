import axios from "axios";
import { touchstart } from "dom7";
import UTILS from "../core/utils";
import Analytics from '../core/analytics';


export default class BBFindFund {
    constructor(el) {
        this.el = el;
        this.apiURL = el.dataset.apiUrl;
		this.fundSessionKey  = el.dataset.fundSessionKey;
		this.filterType = el.dataset.filterType;
        this.noResultText = el.dataset.noResultText;
        this.funds = [];
        this.matchFunds = [];
        this.searcFundhWrapper = el.querySelector('.search-fund-wrapper');
        this.fundListWrapper = el.querySelector('.fund-list');
        this.fundsInputText = el.querySelector('.fund-search');
		this.viewAllFunds = el.querySelector('a.btn-primary')
        this.ddNavIcon = el.querySelector('.icon.fa-chevron-down');
        this.sessionKeyPrefix = "fund-" + this.fundSessionKey;
        this.searchFundsByName = this.searchFundsByName.bind(this);
    }

    updateListMarkup() {
        let fundListHtml= "";

        if(this.matchFunds.length) {
            this.matchFunds.forEach((fund) => {
			   fundListHtml += `<li><a href="${fund.url}">${fund.fundName}</a></li>`;
            });
        } else {
            fundListHtml = `<li>${this.noResultText}</li>`;
        }

		this.fundListWrapper.innerHTML = fundListHtml;
    }

    searchFundsByName(e) {
        let searchTerm = e.currentTarget.value.toLowerCase();
        if(searchTerm.length) {
            this.matchFunds = this.funds.filter((fund) => fund.fundName.toLowerCase().indexOf(searchTerm) > -1 ? true : false);
        } else {
            this.matchFunds = this.funds;
        }

        this.updateListMarkup();
    }

    async getFunds() {
        let {countryCode, investType} = UTILS.getCookieObject();
        let language = UTILS.getCountryLanguage(countryCode);
        language = language.length ? language : "en";

        let sessionKey = `${this.sessionKeyPrefix}-${countryCode}-${investType}`;
        let fundList = JSON.parse(UTILS.getSessionStorage(sessionKey));
        if(fundList) {
            this.funds = fundList;
            return;
        }

        let {status, data} = await axios(this.apiURL, {
            params: {
                countrycode: countryCode,
                language: language,
                mode: investType,
				pageid: this.fundSessionKey
            }
        });

        if(status == 200 && data.funds) {
            this.funds = data.funds;
            UTILS.setSessionStorage(sessionKey, JSON.stringify(data.funds));
        }
    }

    bindEvents() {
        this.fundsInputText.addEventListener('input', this.searchFundsByName);

        this.fundsInputText.addEventListener('click', (e) => {
            e.stopPropagation();
			document.querySelectorAll('.search-fund-wrapper').forEach(wrapper => {
				wrapper.classList.remove('active');
			});
            this.searcFundhWrapper.classList.add('active');
            this.searchFundsByName(e);
        });

        // this.fundsInputText.addEventListener('blur', UTILS.debounce(()=> {
        //     this.searcFundhWrapper.classList.remove('active');
        //     },200)
        // );

        document.querySelector('body').addEventListener('click', UTILS.debounce(()=> {
                this.searcFundhWrapper.classList.remove('active');
            },200)
        );

        this.ddNavIcon.addEventListener('click', (e) => {
            this.searcFundhWrapper.classList.toggle('active');
            let hasClass =  this.searcFundhWrapper.classList.contains('active');
             (hasClass) ? this.fundsInputText.focus() : this.fundsInputText.focusOut();
        });

		this.viewAllFunds.addEventListener('click', (e) => {
			let eventObj = {
				event: (this.filterType + '_view-fund-center'),
				action_item: e.target.innerText,
			};

			Analytics.pushToAnalytics(eventObj);
        });

		this.fundListWrapper.addEventListener('click', (e) => {
			let eventObj = {
				event: (this.filterType + '_fund_filter'),
				fund_title: e.target.innerText,
			};

			Analytics.pushToAnalytics(eventObj);
		});
    }

    init() {
        this.bindEvents();
        this.getFunds();
    }
}

window.addEventListener('DOMContentLoaded', (event) => {
    let findFunds = document.querySelectorAll('.bb-find-fund');

	if(findFunds.length > 0) {
		findFunds.forEach(fund => {
			new BBFindFund(fund).init();
		});
	}
});
