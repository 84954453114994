export default class BBDropDown {
    constructor (element, options={}) {
        this.selectors = {
            selectedItem: '.btn-dropdown',
            dropdownMenu: '.dropdown-menu',
            menuItems: '.dropdown-item',
			activeItem: '.dropdown-item.active'
        };
        this.element = element;
        this.handleDDClick = this.handleDDClick.bind(this);
        this.options = options;
        this.init();
    }

    getItemFromValue(value) {
        return Array.from(this.element.querySelectorAll(this.selectors.menuItems)).filter((item) => item.dataset.value == value);
    }

    makeItemActive(item) {
        item.classList.add('active');
        this.element.querySelector(this.selectors.selectedItem).innerHTML = item.text;
        if(item.dataset.value) {
            this.element.querySelector(this.selectors.selectedItem).dataset.value = item.dataset.value;
        }
    }

    resetActiveItem() {
        let activeItem = this.element.querySelector(this.selectors.activeItem)
        if(activeItem) {
            this.element.querySelector(this.selectors.activeItem).classList.remove('active');
        }
    }

    handleDDClick(e) {
        e.preventDefault();
        this.resetActiveItem();
        this.makeItemActive(e.target);
        this.onChange(e);
    }

    onChange(e) {
        let {onChangeHandler} = this.options
        if(onChangeHandler) {
            onChangeHandler(e);
        };
    }

    getSelectedValue() {
        const selectedItem = this.element.querySelector(this.selectors.selectedItem);
        return { dispalyValue: selectedItem.textContent, value: selectedItem.dataset.value ? selectedItem.dataset.value : ''};
    }

    setValue(value) {
        this.element.querySelectorAll(this.selectors.menuItems).forEach(item => {
            if(item.dataset.value == value) {
                item.dispatchEvent(new Event('click'));
            }
        });
    }

    init() {
        this.element.querySelectorAll(this.selectors.menuItems).forEach(item => {
            item.addEventListener('click', this.handleDDClick);
        });
    }
}
