import { set } from "js-cookie";

class BBLeadStrategists {
    constructor(leadStrategistsWrapper,investmentType, index, data) {
        this.element = leadStrategistsWrapper;
		this.strategistsList = data.strategists;
		this.investmentType = investmentType;
		this.specialsimsList = investmentType.specialisms;
		this.formatedStrategistByCategory = {};
        this.formatedSpecialismByCategory = {};
        this.stragtegistBySpecialism = {default:[]};
		this.tabID = investmentType.name.toLowerCase().replace(/ /g,"_") + "_" + index
    }

	addUniqueIdentifierToStragtegist() {
        this.strategistsList.forEach((item, index) => {
            item.personid = index;
        });
    }

	removeDuplicateStragtegistFromSpecialism() {
        Object.keys(this.stragtegistBySpecialism).forEach(specialism => {
            if(specialism != 'default') {
                let personSet = new Set();
                let strategistForSpecialims = this.stragtegistBySpecialism[specialism];
                if(strategistForSpecialims) {
                    let filterArray = strategistForSpecialims.filter(person => {
                        if(personSet.has(person.personid)) {
                            return false;
                        } else {
                            personSet.add(person.personid);
                            return true;
                        }
                    });
                    this.stragtegistBySpecialism[specialism] = filterArray;
                } else {
                    //Delete specialism that don't have any strategist against it
                    delete this.stragtegistBySpecialism[specialism];
                }
            }
        });
    }

	isStrategistForDefault(item) {
		for(var i =0; i< this.specialsimsList.length; i++) {
			for(var j =0; j< this.specialsimsList[i].categories.length; j++) {
				var category = this.specialsimsList[i].categories[j];

				if(item.specialism.split(",").includes(category.toString())) {
					return true;
				}
			}
		}

		return false
	}


    groupStrategistBySpecialism() {

		this.strategistsList.forEach(item => {
			if(this.isStrategistForDefault(item)) {
				this.stragtegistBySpecialism.default.push(item);
			}
        });

        //Iterate over actual Specialism by category and push record against
        //strategist by category against it
        this.specialsimsList.forEach(specialism => {
            specialism.categories.forEach(category => {
                let strategistByspecialismExist = this.stragtegistBySpecialism[specialism.specialism];
                const strategistList = this.formatedStrategistByCategory[category];
                this.stragtegistBySpecialism[specialism.specialism] = strategistByspecialismExist ? strategistByspecialismExist.concat(strategistList) : strategistList;
            });
        });
    }

    groupStrategistByCategories () {
        this.strategistsList.forEach(item => {
            //this.formatedStrategistByCategory['default'] = this.formatedStrategistByCategory['default'].concat(item);
            item.specialism.split(",").forEach(specialism => {
                if(specialism.length) {
                    let specialismExist = this.formatedStrategistByCategory[specialism];
                    const currentItem = new Array(item);
                    this.formatedStrategistByCategory[specialism] =  specialismExist ? specialismExist.concat(currentItem) : currentItem;
                }
            });
        });
    }

    getTabContent(items) {
        let markup = '';
        let totalCount = items.length;

        items.forEach((item, idx) => {
            let addMarginTop = totalCount / 4 > 1 ? false : true;
            markup += `<div class="col-12 col-md-6 col-lg-3 bb-card-wrapper">
            <div class="bb-card">
                <a href="${item.findMoreLink}" class="bb-card--link">
                    <div class="bb-card--image">
                        <picture class="bb-card--photo">
                        <source srcset="${item.large}" media="(min-width: 1024px)">
                        <img src="${item.default}" alt="">
                        </picture>
                    </div>
                </a>
                <div class="bb-card--caption">
                    <a href="${item.findMoreLink}" class="bb-card--link">
                        <h4>${item.title}</h4>
                    </a>
                    <h6 class="bb-card--subtitle">${item.subtitle}</h6>
                    <div class="bb-card--bottom-content">
                    <a href="${item.findMoreLink}" class="bb-card--findmore">
                    <span class="bb-card--find">${item.findMoreText}<span class="icon fas fa-chevron-right"></span></span>
                    </a>
                </div>
            </div></div></div>`;
        });

        return markup
    }

    renderMarkup () {
		let tabElement = "";
		let tabContentEle = "";

        Object.keys(this.stragtegistBySpecialism).forEach((key, index) => {
            tabElement += `<li class="nav-item" role="presentation">
                <a class="nav-link${index == 0 ? ' active': ''}" data-bs-toggle="tab" href="#tab-${this.tabID}-${index}" role="tab" aria-controls="${key}" aria-selected="true">${key == 'default' ? this.investmentType.name : key}</a>
            </li>`;

            tabContentEle += `<div class="tab-pane fade${index == 0 ? ' show active' : ''}" id="tab-${this.tabID}-${index}" role="tabpanel" aria-labelledby="tab-${key}">
                <div class="container"><div class="row strategist-list">
                ${this.getTabContent(this.stragtegistBySpecialism[key])}
            </div></div></div>`;
        });

        if(this.stragtegistBySpecialism && this.stragtegistBySpecialism.default && this.stragtegistBySpecialism.default.length > 0) { 
            document.getElementById(this.tabID).innerHTML = tabElement;
            document.getElementById(this.tabID + "_content").innerHTML = tabContentEle;
            document.getElementById(this.tabID).closest('.main-tabs-wrapper').classList.add('show')
        }
    }

	investmentTypeTabs () {
		let investmentTypeHTML = `<div class="container main-tabs-wrapper">
			<div class="row bb-component-header">
				<div class="col-12 bb-component-header-title">
					<h3>${this.investmentType.title}</h3>
				</div>
			</div>
			<div class="row" >
				<div class="col-12">
					<div class="bb-tabs-wrapper scrollable" >
						<a href="" class="link-arrow-left arrow-prev tab-control" aria-label="previous button"></a>
						<ul class="nav nav-tabs bb-tabs" id="${this.tabID}" role="tablist"></ul>
						<a href="" class="link-arrow-right arrow-next tab-control" aria-label="next button"></a>
					</div>
				</div>
				<div class="tab-content" id="${this.tabID}_content"></div>
			</div>
		</div> `;
		this.element.innerHTML += investmentTypeHTML;
    }




    init() {
		this.addUniqueIdentifierToStragtegist();
		this.groupStrategistByCategories();
        this.groupStrategistBySpecialism();
		this.removeDuplicateStragtegistFromSpecialism();
		this.investmentTypeTabs();
		this.renderMarkup();
    }
}

window.addEventListener('DOMContentLoaded', (event) => {
    const leadStrategistsWrapper2 = document.querySelector('.bb-lead-strategists-v2');
    const strategists = window.BB && window.BB.strategistsData2;

	if(strategists && strategists.investmenttypes && strategists.investmenttypes.length > 0) {
		strategists.investmenttypes.forEach((investmenttypes, index) => {
			new BBLeadStrategists(leadStrategistsWrapper2, investmenttypes, index, strategists).init();
		});
	}

});
