import Swiper, {Navigation, Pagination} from 'swiper';
import {carouselMetadata} from '../core/constant';
import UTILS from '../core/utils';

Swiper.use([Navigation, Pagination]);

export default class BBCarousel {
    constructor(carousel) {
        this.swiperInstance = undefined;
        this.carousel = carousel;
        let dataSet = carousel.dataset;
        this.mobileOnly = dataSet.mobileOnly && dataSet.mobileOnly == "true" ? true : false;
        this.carouselOptions = carouselMetadata.general;
        this.carouselElement = `[js-carousel-idx='${carousel.getAttribute("js-carousel-idx")}']`;
        this.resizeHandler = this.resizeHandler.bind(this);
    }

    resizeHandler() {
        if(this.mobileOnly && window.outerWidth < 768 && this.swiperInstance == undefined) {
            this.initSwiper();
        }

        if(this.mobileOnly && window.outerWidth >= 768 && this.swiperInstance != undefined) {
            this.swiperInstance.destroy(true, true);
            this.swiperInstance = undefined;
            this.removeCarouselMarkup();
        }
    }

    addCarouselMarkup() {
        //Add swiper classes and inject markup for pagination and buttons
        let sliderWrapper = this.carousel.querySelector(this.carouselOptions.selectors.sliderWrapper);
        let carouselControls = document.createElement("div");
        carouselControls.setAttribute("class", "bb-carousel-controls");
        carouselControls.innerHTML = `<div class="swiper-button-prev"></div><div class="swiper-pagination"></div>
        <div class="swiper-button-next"></div>`;
        sliderWrapper.insertAdjacentElement("afterend", carouselControls);

        sliderWrapper.classList.add('swiper-wrapper');
        sliderWrapper.querySelectorAll(this.carouselOptions.selectors.slides).forEach((slide) => {
            slide.classList.add('swiper-slide');
        });
    }

    removeCarouselMarkup() {
        //remove swiper classes and injected markup for pagination and buttons
        let sliderWrapper = this.carousel.querySelector(this.carouselOptions.selectors.sliderWrapper);
        let carouselControls = this.carousel.querySelector(".bb-carousel-controls");
        carouselControls.remove();
        sliderWrapper.classList.remove('swiper-wrapper');
        sliderWrapper.querySelectorAll(this.carouselOptions.selectors.slides).forEach((slide) => {
            slide.classList.remove('swiper-slide');
        });
    }

    initSwiper() {
        this.addCarouselMarkup();
        this.swiperInstance = new Swiper(this.carouselElement, this.carouselOptions.carouselConfig);
    }

    init() {
        if(!this.mobileOnly || window.outerWidth < 768) {
            this.initSwiper();
        } else {
            window.addEventListener("resize", UTILS.debounce(this.resizeHandler));        
        }
    }
}

window.addEventListener('DOMContentLoaded', (event) => {
    let carousels = document.querySelectorAll('.bb-carousel');
    carousels.forEach((carousel, idx) => {
        //Make a module carousel only if it has atleast 4 slides
        //Although this option is configurable via data-min-slide-count attribute
        let minSlideCount = carousel.dataset.minSlideCount ? carousel.dataset.minSlideCount : 4;

        if(carousel.querySelectorAll(carouselMetadata.general.selectors.slides).length >= minSlideCount) { 
            carousel.setAttribute("js-carousel-idx", idx);
            new BBCarousel(carousel).init();
        }
    });
});