import UTILS from '../core/utils';

const AnalyticObjects = {
    "siteoveraly": {
        'event': 'investor_preference_confirmed',
        'invester_type': '<<institutional/professional>>',
        'location': '<<country>>',
    },
    "generic": {
        'event': 'generic_click',
        'action_type': '<<navigation expannded/closed>>',
        'link_text': '<<link-text>>',
        'module_name': '<<module_name_as_confluence>>'
    },
    "insightcardclick": {
        'event': 'card_click',
        'card_title': '<<title_name_as_confluence>>',
        'path': '<<path>>',
        'card_type': '<<insight-card>>',
        'module_title': '<<module_title_as_confluence>>',
        'module_name': '<<module_name_as_confluence>>',
        'card_position': '<<position_of_card>>',
        'Module_postion': '<<module_position>>'
    },
    "filterclick": {
        'event': 'insights_filter_applied'
    },
    "bookmarkclick": {
        'event': 'view_bookmark',
        'content_page_title': '<<content_page_title>>'
    },
    "bookmarkarticleclick": {
        'event': 'bookmark_content',
        'content_page_title': '<<content_page_title>>'
    },

    "podcastclick": {
        'event': 'podcast_interaction',
        'action_type': '<<start/seek/pause>>',
        'media_id': '<<id_of_podcast>>',
        'media_length': '<<total length of the podcast>>'
    },
    "SocialshareClick": {
        'event': 'social_share',
        'social_media_name': '<<linkedin/email/twitter>>',
        'content_page_title': '<<content_page_title>>'
    },
    "SocialshareClick": {
        'event': 'social_share',
        'social_media_name': '<<linkedin/email/twitter>>',
        'content_page_title': '<<content_page_title>>'
    },
    "showCookieBanner": {
        'event': 'load_one_trust_banner',
        'refresh_page': '',
        'page_url': ''
    }
}

const getAnalyticsObjectFor = (key) => {
    return Object.assign({}, AnalyticObjects[key]);
}

const addGLobalParams = () => {
    const siteSectionObj = UTILS.getSiteSections();
    const analyticsObj = {};
    analyticsObj.investor_type = siteSectionObj.investor_type;
    analyticsObj.primary_site_section = siteSectionObj.primary_site_section;
    analyticsObj.secondary_site_section = siteSectionObj.secondary_site_section;
    analyticsObj.page_section = siteSectionObj.page_section;

    return analyticsObj;
}

const pushToAnalytics = (eventObj) => {
    if (typeof dataLayer !== 'undefined' && Array.isArray(dataLayer)) {
        const globalParams = addGLobalParams();
        const analyticsObj = { ...eventObj, ...globalParams };
        dataLayer.push(analyticsObj);
    }
}

const analyticHandler = (e) => {
    //e.preventDefault();
    let item = e.currentTarget;
    let analyticsEvent = item.dataset.analytics;
    try {
        pushToAnalytics(JSON.parse(analyticsEvent));
    } catch (e) {
        console.error("error pushin to analytics", e);
    }
}

const getDownloadableLinks = () => {
    document.addEventListener("click", (event) => {
        const keydocument = event.target.closest('.keydoc-accordion .accordion-body a');
        let file_type = '';
        let element_type = '';
        const link_type = event.target.classList.length === 0 && event.target.closest('.pdf-link') !== null;        
        const bbCard = event.target.closest('.bb-card');
        const fundsDetailPage = event.target.closest('.funds-detail-page');
        let fund_type = '';
        let eventContainer = event.target.closest("a");
        const keydocument_link = event.target.closest('.keydoc-accordion li a');
        const pdf_doc = event.target.closest('.bb-rich-text .pdf-link a');
        let pdf_doc_file_type = '';
        let pdf_links_keydocument = '';
        let pdf_links_accordian_name;
        let pdf_links_keydocument_name;
        let pdf_link = event.target.closest('.accordion-body .pdf-link');

        //bb-rich-text with pdf_link
        const disclosures = event.target.closest('.bb-rich-text .pdf-link');
        //bb-rich-text with pdf_link and a
        if (pdf_doc) {
            pdf_doc_file_type = pdf_doc.title;
        }
        //pdf-links in general accordian
        if (pdf_link && pdf_link.querySelector('a').title) {
            pdf_links_accordian_name = event.target.closest('a').title;
        }
        //pdf_links under keydocuments accordian
        if (keydocument) {
            pdf_links_keydocument = event.target.closest('.accordion-item');
            pdf_links_keydocument_name = pdf_links_keydocument.querySelector('.accordion-header .accordion-button').innerText;
        }
        //pdf_links under keydocuments without accordian
        if (keydocument_link) {
            file_type = keydocument_link.innerText;
        }

        if (bbCard) {
            fund_type = bbCard.querySelector('.bb-card--caption h4').innerText
        } else if (fundsDetailPage) {
            fund_type = fundsDetailPage.querySelector('.bb-page-header h1').innerText
        } else {
            fund_type = "not applicable"
        }

        if (eventContainer) {
            let hrefVal = eventContainer.href;
            let hrefValFull = hrefVal.split(".").pop();
            let hrefValExtn;
            const file_name = hrefVal.split('/').pop().split('.').slice(0, -1).join('.')
            hrefValFull.includes('?') ? hrefValExtn = hrefValFull.replace(hrefValFull.substr(hrefValFull.indexOf('?'), hrefValFull.length + 1), '') : hrefValExtn = hrefValFull;
            if (link_type === true) {
                element_type = 'hyper-link';
            } else {
                element_type = 'button';
            }
            if (hrefValExtn == "pdf" || hrefValExtn == "csv" || hrefValExtn == "docs" || keydocument_link) {
                let moduleContainer = "";
                if (event.target.closest(".bb-card")) {
                    moduleContainer = "Card";
                }
                else if (event.target.tagName === "BUTTON") {
                    moduleContainer = "Button";
                }
                else {
                    moduleContainer = "Link";
                }

                const downloadLinkData = {
                    'event': 'website_asset_downloads',
                    'action_type': 'Download Action',
                    'link_text': eventContainer.innerText,
                    'path': eventContainer.pathname,
                    'module_Name': moduleContainer,
                    'fund_title': fund_type,
                    'file_title': file_name,
                    'file_type': (disclosures && disclosures.title) || (pdf_links_accordian_name) || (pdf_links_keydocument && pdf_links_keydocument_name) || (pdf_doc && pdf_doc_file_type) || file_type,
                    'element_type': element_type,
                };
                pushToAnalytics(downloadLinkData)
                return true;
            }
        }
    })
}

const bindAnalyticEvents = () => {
    let analyticsItems = document.querySelectorAll('[data-analytics]');
    analyticsItems.forEach(ele => {
        ele.removeEventListener('click', analyticHandler);
        ele.addEventListener('click', analyticHandler);
    });
}

document.addEventListener("DOMContentLoaded", () => {
    bindAnalyticEvents();
    getDownloadableLinks();
});

const Analytics = {
    bindAnalyticEvents,
    pushToAnalytics,
    getAnalyticsObjectFor
};

export default Analytics;
