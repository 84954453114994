import Cookie from 'js-cookie';
import { investorType, constants} from '../core/constant';
import BBDropDown from './bb-dropdown';
import UTILS from '../core/utils';
import axios from 'axios';


export default class genericTabs {
    constructor(el) {
        this.el = el;
    }

    addActiveTab() {
       
        let navlink = document.querySelector(".funds-tab-link.nav-link")
        let navtab = document.querySelector("#genericTabContent .tab-pane")
       
        if(navlink && navtab){
            let navtabid = navlink.dataset.fundId
            let navtabcontent = navtab.dataset.fundId

            if (navtabid == navtabcontent) {
                navlink.classList.add("active")
                navtab.classList.add("show" , "active")
            }
        }
    }

    init() {
        this.addActiveTab()
    }    

}


window.addEventListener('DOMContentLoaded', (event) => {
    const genericTab = document.querySelector('.bb-generic-tabs');
    if(genericTab) {    
        new genericTabs(genericTab).init()
    }
});