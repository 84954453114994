import BBDropDown from './bb-dropdown';
import BBPagination from './bb-pagination';
import UTILS from '../core/utils';

import axios from 'axios';

class BBMediaCenter {
    constructor(mediaCenterWrapper, data) {


        this.element = mediaCenterWrapper;
        this.mediaList = data.publicationyears;
		this.settings = data.settings;
		this.mediaFilterDD;
		this.mediaFilterMonthDD;
		this.latestYear = UTILS.getQueryParam('y');
		this.latestMonth = UTILS.getQueryParam('m');
		this.apiURL = this.element.dataset.apiUrl;
		this.readmoreText = this.element.dataset.readmoreText;
		this.listingState;
        this.selectors = {
            mediaFilterDD: '#mediafilter',
			mediaFilterDDMenu: '#mediafilter .dropdown-menu',
			mediaFilterDDMenuLinks: '#mediafilter .dropdown-menu .dropdown-item',
            pagination: '.bb-pagination',
            mediapub : ".media-publication-wrapper",
            selectedText: '#mediacenterdropdown > a',
            mediaPubMonthDD : "#mediaFilterMonths",
			mediaPubMonthDDMenu: '#mediaFilterMonths .dropdown-menu',

        };

        this.pagination = new BBPagination(this.element.querySelector(this.selectors.pagination), {
            onPageChangeHandler: this.pageChanged.bind(this)
        });
    }

	//Reset listng state
    resetListingState() {
		this.pagination.paginationState.currentPage = 1;

		this.listingState = {
            currentPage: this.pagination.paginationState.currentPage,
            pageSize: this.element.dataset.limit ? this.element.dataset.limit : 12,
            totalCount: 0,
            noResultMsg: "",
        };

    }

    //Page Change event handler
    pageChanged(e) {
        this.listingState.currentPage = this.pagination.paginationState.currentPage;
		this.setCurrentPublication(this.mediaFilterDD.getSelectedValue().value, this.mediaFilterMonthDD.getSelectedValue().value);

    }

    renderFilterYearMarkup() {
		document.querySelector(this.selectors.mediaFilterDDMenu).innerHTML= '';
        this.mediaList.forEach((data, index)=> {
			if(!this.latestYear && index === 0) this.latestYear = data.title;

            let filterList = `<li><a class="dropdown-item" data-value="${data.title}" href="#${data.title}">${data.title}</a></li>`;
            document.querySelector(this.selectors.mediaFilterDDMenu).innerHTML += filterList;
			
			document.querySelector(this.selectors.mediaFilterDDMenuLinks).addEventListener('click', e =>{
				e.preventDefault()
			})
        });


		// Bind on change event as we now have added Valued to dropdown
		this.mediaFilterDD = new BBDropDown(document.querySelector(this.selectors.mediaFilterDD), {
			onChangeHandler: this.filterYearDDChanged.bind(this)
        });

		// Set Active to trigger on load Change
		if(this.latestYear) {
			this.mediaFilterDD.setValue(this.latestYear);		
		}

		//this.filterYearDDChanged();
    }

	filterYearDDChanged(e) {
		this.renderFilterMonthMarkup(this.mediaFilterDD.getSelectedValue().value);
		if(this.mediaFilterDD.getSelectedValue().value)
			UTILS.addQueryParam('y', this.mediaFilterDD.getSelectedValue().value)

			if (e.currentTarget) this.latestMonth=""
	}

	renderFilterMonthMarkup(currentYear) {
		// reset Month List
		document.querySelector(this.selectors.mediaPubMonthDDMenu).innerHTML = "";

		this.mediaList.forEach((data)=> {
			if(currentYear === data.title)  {

				data.publicationmonths.forEach((data, index) =>{
					if(!this.latestMonth && index === 0) this.latestMonth = data.name.toLowerCase();

					let mediaPubMonthList = `<li><a class="dropdown-item ${this.latestMonth === data.name.toLowerCase()?'active':''}" data-value="${data.name.toLowerCase()}" href="#${data.month}" data-analytics='${data.gtmscript}'>${data.month}</a></li>`;
					document.querySelector(this.selectors.mediaPubMonthDDMenu).innerHTML += mediaPubMonthList;
				})
			}
			document.querySelector(this.selectors.mediaPubMonthDD).addEventListener('click', e =>{
				e.preventDefault()
			})
        });

		// Bind on change event as we now have added Valued to dropdown
		this.mediaFilterMonthDD = new BBDropDown(document.querySelector(this.selectors.mediaPubMonthDD), {
			onChangeHandler: this.filterMonthDDChanged.bind(this)
        });

		// Set Active to trigger on load Change
		if(this.latestMonth) {
			this.mediaFilterMonthDD.setValue(this.latestMonth);
		}

		this.filterMonthDDChanged();
	}

	filterMonthDDChanged(e) {
		this.resetListingState();
		this.setCurrentPublication(this.mediaFilterDD.getSelectedValue().value, this.mediaFilterMonthDD.getSelectedValue().value);

		if(this.mediaFilterMonthDD.getSelectedValue().value)
		UTILS.addQueryParam('m',this.mediaFilterMonthDD.getSelectedValue().value);
        this.renderPagination();
	}

	setCurrentPublication (year, month) {
        this.mediaList.forEach(data => {

			if(data.title === year) {
				data.publicationmonths.forEach(data => {
						if(data.name.toLowerCase() === month.toLowerCase()) {
							if( data.publications) {
								this.renderPublicationMarkup(data);
							} else {
								this.fetchMediaList(data.pageid, year, month)
							}
						}
					})
				}

			});
    }

	renderPublicationMarkup(data) {
		let publicationList = "";

		// set paganiation
		this.listingState.totalCount = data.publications.length;
		const currentMaxIndex = this.listingState.currentPage  * this.listingState.pageSize;

		data.publications.slice(currentMaxIndex - this.listingState.pageSize, currentMaxIndex).forEach((data) => {
			publicationList += `<div class="media-center-item">
					<div class="media-center-info body--data color--grey">
					<span>${data.date}</span> | <span>${data.name}</span>
					</div>
					<div class="media-center-title color--dark-blue-tint-1">
						<a href=${data.url} rel="noopener" target="_blank" data-analytics='${data.gtmscript}'><h5>${data.title}</h5></a>
					</div>
						<div class="media-center-description color--dark-grey">${data.description}</div>
					<div class="media-center-link">
						<a href=${data.url} rel="noopener" target="_blank" data-analytics='${data.gtmscript}' class="link-arrow-right">
							${this.readmoreText}
						</a>
					</div>
				</div>`;
		});

		document.querySelector(this.selectors.mediapub).innerHTML = publicationList;
		this.scrollToListingView();
	}

	async fetchMediaList(pageid, year, month) {
        let response = await axios(this.apiURL, {
            params: {
                PageId: pageid,
				language: UTILS.getCountryLanguage(UTILS.getCookieObject().countryCode)
            }
        });

		this.mediaList.forEach(data => {
			if(data.title === year) {
				data.publicationmonths.forEach(data => {
					if(data.name === month) {
						data.publications = response.data.publications;
					}
				});
			}
		});
		this.renderPublicationMarkup(response.data);
        this.renderPagination();
    }

    //Take the current total count and form the elements
     renderPagination() {
         let paginationInstance = this.pagination
         paginationInstance.paginationState.totalItemsCount = this.listingState.totalCount;
         paginationInstance.paginationState.pageSize = this.listingState.pageSize;
         paginationInstance.paginationState.pageCount = Math.ceil(this.listingState.totalCount / this.listingState.pageSize);

		 //show if page count is greater then 1
         if(paginationInstance.paginationState.pageCount > 1) {
             paginationInstance.render();
         } else {
             paginationInstance.hide();
         }
     }

	 //Scroll to top of listing component
	 scrollToListingView() {
        window.scrollTo({
            top: this.element.closest('.bb-theme').offsetTop - 30,
            left: 0,
            behavior: 'auto'
        });
    }

    init() {
		this.resetListingState();
		this.renderFilterYearMarkup();
        this.renderPagination();

		var page_loaded = false;

		window.addEventListener('popstate', (event) => {
			if (!page_loaded) {
				page_loaded = true;
				this.latestYear = UTILS.getQueryParam('y');
				this.latestMonth = UTILS.getQueryParam('m');
				this.resetListingState();
				this.renderPagination();
				this.renderFilterYearMarkup();
				return false;
			 } 
		});
    }

}

window.addEventListener('DOMContentLoaded', (event) => {
    const mediaCenterWrapper = document.querySelector('.bb-media-center-list');
    const mediaCenterData = window.BB && window.BB.MediaCenterData
    if(mediaCenterWrapper && mediaCenterData) {
        new BBMediaCenter(mediaCenterWrapper , mediaCenterData).init();
    }
});



