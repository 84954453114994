import UTILS from '../core/utils';

export default class BBPagniaton {
    constructor(element, options={}) {
        this.selector = {
            pageCountWrapper: '.page-count-wrapper'
        };
        this.element = element;
        this.paginationState = {
            currentPage: 1,
            totalItemsCount: 0,
            pageSize: 0,
            pageCount: 0
        };
        this.options = options;
        this.MOBILE_VIEW_PORT = 768;
        this.isMobile = false;
        this.bindEvents();
    }

    render() {
        if(this.element.classList.contains('d-none')) {
            this.element.classList.remove('d-none');
        }
        
        let pageCountWrapper = this.element.querySelector(this.selector.pageCountWrapper);
        let pagesToShow = this.isMobile ? this.paginationForMobile() : this.pagination();

        pageCountWrapper.innerHTML = '';
        pagesToShow.forEach(item => {
            pageCountWrapper.appendChild(this.getPageElement(item));
        });

        this.updatePageControls();
    }

    getPageElement(text) {
        let listElement = document.createElement("li");
        let child;
        if(parseInt(text)) {
            child = document.createElement("a");
            child.setAttribute('href', '#');
            child.addEventListener('click', this.onPageItemClick.bind(this));
        } else {
            child = document.createElement("span");
        }
        
        if(this.paginationState.currentPage == text) {
            child.classList.add("active");
        }

        child.classList.add("page-link");
        child.appendChild(document.createTextNode(text));
        listElement.appendChild(child);

        return listElement
    }

    onPageItemClick(e) {
        e.preventDefault();
        const pageItem = e.target
        if(pageItem.classList.contains('active')) {
            return;
        }
        this.paginationState.currentPage = parseInt(pageItem.text);
        this.render();

        //Some event to notify parent component;
        let {onPageChangeHandler} = this.options;
        if(onPageChangeHandler) {
            onPageChangeHandler(e);
        }
    }
    
    // below pagination method is an refererne from and used for desktop version only
    // https://gist.github.com/kottenator/9d936eb3e4e3c3e02598#gistcomment-3238804;
    pagination() {
        let delta;
        const {currentPage, pageCount} = this.paginationState;

        if (pageCount <= 7) {
          // delta === 7: [1 2 3 4 5 6 7]
          delta = 7
        } else {
          // delta === 2: [1 ... 4 5 6 ... 10]
          // delta === 4: [1 2 3 4 5 ... 10]
          delta = currentPage > 4 && currentPage < pageCount - 3 ? 2 : 4
        }
        
        const getRange = (start, end) => {
            return Array(end - start + 1)
              .fill()
              .map((v, i) => i + start)
        }

        const range = {
          start: Math.round(currentPage - delta / 2),
          end: Math.round(currentPage + delta / 2)
        }
      
        if (range.start - 1 === 1 || range.end + 1 === pageCount) {
          range.start += 1
          range.end += 1
        }
      
        let pages =
          currentPage > delta
            ? getRange(Math.min(range.start, pageCount - delta), Math.min(range.end, pageCount))
            : getRange(1, Math.min(pageCount, delta + 1))
      
        const withDots = (value, pair) => (pages.length + 1 !== pageCount ? pair : [value])
      
        if (pages[0] !== 1) {
          pages = withDots(1, [1, '...']).concat(pages)
        }
      
        if (pages[pages.length - 1] < pageCount) {
          pages = pages.concat(withDots(pageCount, ['...', pageCount]))
        }
        
        return pages
    }

    // As the one above has limitation with respect to total number of items and it wasn't working for mobile design
    // it was best suited to use a simple pagination for mobile which shows
    // [{currentPage}, nextPage, ..., lastPage];
    // [firstPage, ..., {secondLast}, lastPage];
    // [firstPage, secondPage, secondLastPage, lastPage];
    // Just 4 items rather then modifying above code sinppet it was best to write one from scratch.
    paginationForMobile() {
        const {currentPage, pageCount} = this.paginationState;
        let delta = 2,
            left = currentPage,
            right = currentPage + delta,
            pages = [];

        if(pageCount <= delta + 2) {
            pages = Array.from({length: pageCount}, (v, k) => k + 1);
            return pages;
        }
        
        pages = Array.from({length: pageCount}, (v, k) => k + 1).filter(i => i && i >= left && i < right);

        if(currentPage < (pageCount - 1)) {
            let arr = [pageCount];
            
            if(currentPage == (pageCount - 2)) {
                pages.unshift('...');
            } else {
                arr.unshift('...');
            }
            
            pages = pages.concat(arr);
        } else {
            let arr = currentPage == pageCount ? [1, '...', pageCount - 1] : [1, '...'];
            pages = arr.concat(pages);
        }

        return pages;
    }

    updatePageControls() {
        const {currentPage, pageCount} = this.paginationState;
        
        //IS only page
        if(pageCount == 1) {
            this.element.querySelectorAll('.page-control, .arr-icon').forEach(item => {
                item.classList.remove("active");
            });
            return;
        }

        const pageDivison = (pageCount/currentPage);
        const pagePos =  pageDivison == pageCount ? "first" : (pageDivison == 1 ? "last" : "between");

        switch (pagePos) {
            case 'first':
                this.element.querySelectorAll('.page-control, .arr-icon').forEach(item => {
                    //activate next and last page control
                    if(item.classList.contains('next') || item.classList.contains('goto-last-page')) {
                        item.classList.add("active");
                    } else {
                        item.classList.remove("active");
                    }
                });
                break;
            case 'last':
                this.element.querySelectorAll('.page-control, .arr-icon').forEach(item => {
                    //activate prev and first page control
                    if(item.classList.contains('prev') || item.classList.contains('goto-first-page')) {
                        item.classList.add("active");
                    } else {
                        item.classList.remove("active");
                    }
                });
                break;
            case 'between':
                this.element.querySelectorAll('.page-control, .arr-icon').forEach(item => {
                    //activate all page control
                    item.classList.add("active");
                });
                break;
        }
    }

    pageControlHandler(e) {
        e.preventDefault();
        let currentEleClassList = e.target.classList;
        if(currentEleClassList.contains('active')) {
            
            if(currentEleClassList.contains('goto-first-page')) {
                this.paginationState.currentPage = 1;
            }

            if(currentEleClassList.contains('goto-last-page')) {
                this.paginationState.currentPage = this.paginationState.pageCount;
            }
            
            if(currentEleClassList.contains('prev')) {
                this.paginationState.currentPage -= 1;
            }

            if(currentEleClassList.contains('next')) {
                this.paginationState.currentPage += 1;
            }

            this.render();

            //Some event to notify parent component;
            let {onPageChangeHandler} = this.options;
            if(onPageChangeHandler) {
                onPageChangeHandler(e);
            }
        }
    }

    hide() {
        this.element.classList.add('d-none');
    }

    show() {
        this.element.classList.remove('d-none');
    }

    windowResizeHandler() {
        this.isMobile = window.outerWidth < this.MOBILE_VIEW_PORT ? true : false;
        if(this.paginationState.pageCount > 1) {
            this.render();
        }
    }

    bindEvents() {
        this.element.querySelectorAll('.page-control, .arr-icon').forEach(item => {
            item.addEventListener("click", this.pageControlHandler.bind(this), true);
        });

        if(window.outerWidth < this.MOBILE_VIEW_PORT) {
            this.isMobile = true;
        } else {
            window.addEventListener("resize", UTILS.debounce(this.windowResizeHandler.bind(this)));        
        }

        window.addEventListener('scroll', UTILS.debounce(() => { 
            this.lastPostionInViewPort = this.element.getBoundingClientRect().top;
        }));
    }
}