import Analytics from "../core/analytics";

window.addEventListener('load', () => {
	var addThis = document.querySelector('.bb-addThis');

    if(addThis) {
		var loadURL = addThis.getAttribute('data-addthis-url');
		var interval = setInterval(checkOneTrust, 1000);
		
		function checkOneTrust() {
			if(typeof OneTrust !== "undefined"){
				// console.log(OneTrust)
				OneTrust.InsertScript(loadURL, 'body', getDomAnalytics, null, 'C0003');
		
				var getDomAnalytics = () => {
					let loadedShareEl = document.querySelector('.addthis_inline_share_toolbox')
					if (loadedShareEl){
						var getDataTitle = loadedShareEl.getAttribute('data-title')
						getDataTitle ? clearInterval(getDomAnalytics) : "";
	
						loadedShareEl.querySelectorAll(".at-share-btn").forEach(data=>{
							//for each loop for share btn and get SM carrier via click listener
							data.addEventListener('click' , e =>{
								let eventObj = Analytics.getAnalyticsObjectFor('SocialshareClick');
								eventObj.social_media_name = data.lastElementChild.innerText;
								eventObj.content_page_title = getDataTitle;
								Analytics.pushToAnalytics(eventObj);
							})
						})
					}
				};
				clearInterval(interval);
			};
		}
	}
});