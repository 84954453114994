import BBPagination from './bb-pagination';
import UTILS from '../core/utils';
import axios from 'axios';

export default class BBPodcastListing {
    constructor(element) {
        this.selectors = {
            insightsList: '.podcasts-list',
            pagination: '.bb-pagination'
        };
        this.element = element;
        this.pagination = new BBPagination(this.element.querySelector(this.selectors.pagination), {
            onPageChangeHandler: this.pageChanged.bind(this)
        });
    }

    //Page Change event handler
    pageChanged(e) {
        this.listingState.currentPage = this.pagination.paginationState.currentPage;
        this.fetchInsights().then(() => {
            this.updateListView();
            this.scrollToListingView();
        });
    }
    
    //Scroll to top of listing component
    scrollToListingView() {
        window.scrollTo({
            top: this.element.closest('.bb-theme').offsetTop - 30,
            left: 0,
            behavior: 'auto'
        });
    }

    //Take the current total count and form the elements
    renderPagination() {
        let paginationInstance = this.pagination
        paginationInstance.paginationState.totalItemsCount = this.listingState.totalCount;
        paginationInstance.paginationState.pageSize = this.listingState.pageSize;
        paginationInstance.paginationState.pageCount = Math.ceil(this.listingState.totalCount / this.listingState.pageSize);
        // show if page count is greater then 1
        if(paginationInstance.paginationState.pageCount > 1) {
            paginationInstance.render();
        } else {
            paginationInstance.hide();
        }
    }

    //Generate the no Result call out text markup
    getNoResultMarkup() {
        return `
            <div class="col-12 insights-item card-item">
                <p class="quote color--dark-blue-tint-1">${this.listingState.noResultMsg}<p>
            </div>
        `;
    }
    
    //Generate insight car markup given an data object with fields
    generateCardMarkup(item) {
        let {author, defaultImage, isVideoOrPodcast, link, small, tag, time, title, medium, xlarge, gtmscript} = item;
        let cardMarkup = `
        <div class="col-12 col-md-6 col-lg-4 podcasts-item card-item">
        <div class="bb-card">
        ${defaultImage ? `
            ${link ? `<a href="${link}" class="bb-card--link" data-analytics="${gtmscript}">` : ``}
            <div class="bb-card--image">
                <picture class="bb-card--photo">
                    ${xlarge ? `<source srcset="${xlarge}" media="(min-width: 1200px)" />` : ''}
                    ${medium ? `<source srcset="${medium}" media="(min-width: 768px)" />` : ''}
                    ${small ? `<source srcset="${small}" media="(min-width: 576px)"/>` : ''}
                    <img src="${defaultImage ? defaultImage : 'http://via.placeholder.com/350x205"'}" alt="${title}" >
                </picture>
                ${isVideoOrPodcast ? `<div class="bb-card--play-icon"><i class="fas fa-play"></i></div>` : ''}
            </div>
            ${link ? `</a>` : ``}
            `
        : ""}

        <div class="bb-card--caption">
            ${tag ? `<p class="bb-card--tag">${tag}</p>`: ''}
    
            ${link ? `<a href="${link}" class="bb-card--link"><h4>${title}</h4></a>` : `<h4>${title}</h4>`}

            <div class="bb-card--bottom-content">
                ${time ? `<a href="${link}" class="bb-card--link" >
                    ${isVideoOrPodcast ? `<i class="far fa-play-circle"></i>` : ''}
                    <span class='bb-card--time'>${time}</span>
                </a>` : ''}
            </div>
    
            ${author.name ? 
                `<div class="bb-author">
                    <div class="row">
                        <div class="col-12 ">
                            <div class="bb-author--picture">
                                ${author.picture ? `
                                <img src="${author.picture}" alt="${author.name}" class="rounded-circle" />` : ""}
                            </div>
                            <div class="bb-author--details">
                                <div class="bb-author--name">${author.name}</div><div class="bb-author--date">${author.date}</div>
                            </div>
                        </div>
                    </div>
                </div>` 
                : ``}
        </div>
        </div>
        </div>`;

        return cardMarkup;
    }

    updateListView() {
        const {currentPage, totalCount} = this.listingState;
        const insightsWrapperElement =this.element.querySelector(this.selectors.insightsList);

        //Remove existing cards if any from the wrapper
        insightsWrapperElement.querySelectorAll('.podcasts-item').forEach(item => {
            item.remove();
        });

        if(totalCount == 0) {
            insightsWrapperElement.insertAdjacentHTML('afterbegin', this.getNoResultMarkup());
            return;
        }

        //Add cards to list
        this.listingState.insightslist.forEach((item, index) => {
            insightsWrapperElement.insertAdjacentHTML('beforeend', this.generateCardMarkup(item));
        });
    }

    async fetchInsights() {
        let response = await axios(this.element.dataset.apiUrl, {
                params: {
                  page: this.listingState.currentPage,
                  mode: this.listingState.investType ? this.listingState.investType : ""
                }
            });
        this.listingState.insightslist = response.data.insightslist;
        this.listingState.totalCount = parseInt(response.data.totalCount);
        this.listingState.noResultMsg = response.data.message ? response.data.message : '';
    }

    bindEvents() {
    }

    //set current listing state
    setListingState() {
        let {element} = this;
        this.listingState = {
            currentPage: this.pagination.paginationState.currentPage,
            pageSize: element.dataset.limit ? element.dataset.limit : 12,
            totalCount: 0,
            investType: UTILS.getCookieObject().investType,
            noResultMsg: "",
        };
    }

    getDataAndUpdateView() {
        this.fetchInsights().then(() => {
            this.updateListView();
            this.renderPagination();
        }).catch(err => { console.log("error in request", err) });
    }

    init() {
        this.bindEvents();
        this.setListingState();
        this.getDataAndUpdateView();
    }
}

window.addEventListener('DOMContentLoaded', (event) => {
    let insightsListWrapper = document.querySelector('.bb-podcasts-list-wrapper');
    if(insightsListWrapper) {    
        new BBPodcastListing(insightsListWrapper).init();
    }
});