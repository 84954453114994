import UTILS from '../core/utils';

export default class BBTabs {
    constructor(element) {
        this.element = element;
        this.tabList = element.querySelector('.bb-tabs');
        this.tabControls = element.querySelectorAll('.tab-control');
        this.tabCount = this.tabList.querySelectorAll('.nav-item');
        this.scrollPos = 0;
        this.currentItemScroll = 0;
        this.defaultScrollBy = 1;
        this.elementWidth;
        this.tabListWidth;
    }

    applyHeight() {
        this.element.style.height = `${this.tabList.offsetHeight}px`;
    }

    initalizeScrollElements() {
        this.elementWidth = this.element.offsetWidth;
        this.tabListWidth = this.tabList.scrollWidth;
    }

    checkTabLength () {
        if(this.tabListWidth > this.elementWidth) {
           this.element.querySelector('.arrow-next').classList.add('show');
        } else {
            this.tabControls.forEach(tabControl => {
                tabControl.classList.remove('show');
            });
        }
    }

    //This method will analyze all the parameters and decide
    //which arrows to show, i.e either next, prev or both
    updateControls() {
        if(this.scrollPos == 0) {
         this.element.querySelector('.arrow-prev').classList.remove('show');
         this.element.querySelector('.arrow-next').classList.add('show');
             return;
         }

        this.element.querySelector('.arrow-prev').classList.add('show');
            if (this.scrollPos + this.elementWidth > this.tabListWidth) {
            this.element.querySelector('.arrow-next').classList.add('show');
                if (this.scrollPos + this.elementWidth > this.tabListWidth && this.scrollPos !== 0) {
                this.element.querySelector('.arrow-next').classList.remove('show');
            }
        }
    }

    //This method will return the value by which we need to move th
    // list of tabs
    calculateScrollPos(add) {
        if(add) {
            this.currentItemScroll += this.defaultScrollBy;
        } else {
            this.currentItemScroll -= this.defaultScrollBy;
        }

        let scrollObject = Array.from(this.tabCount).slice(0, this.currentItemScroll);
        this.scrollPos = 0;
        
        scrollObject.forEach(obj => {
            this.scrollPos += obj.offsetWidth;
        });
    }

    //This method will decide how much should the list scroll
    //depending on the current state and parameters
    applyTransition(e) {
        e.preventDefault();

        if(e.target.classList.contains('arrow-next')) {
            this.calculateScrollPos(true);
            if(this.elementWidth + this.scrollPos < this.tabListWidth) {
                this.tabList.style.left = `-${this.scrollPos}px`;
            } else {
                this.tabList.style.left = `-${this.tabListWidth - this.elementWidth}px`;
            }
        }
        
        if(e.target.classList.contains('arrow-prev')) {
            this.calculateScrollPos(false);
            if(this.elementWidth + this.scrollPos > this.elementWidth) {
                this.tabList.style.left = `-${this.scrollPos}px`;
            } else {
                this.tabList.style.left = '0px';
            }
        }

        this.updateControls();
    }

    windowResizeHandler() {
        //do something on resize
        this.initalizeScrollElements();
        this.checkTabLength();
    }

    bindEvents() {
        this.tabControls.forEach(control => {
            control.addEventListener('click', this.applyTransition.bind(this));
        });
        
        //window resize handler to update scroll events
        window.addEventListener("resize", UTILS.debounce(this.windowResizeHandler.bind(this))); 
    }

    init() {
        this.applyHeight();
        this.initalizeScrollElements();
        this.checkTabLength();
        this.bindEvents();
    }
}

window.addEventListener('DOMContentLoaded', (event) => {
    let tabsWrapper = document.querySelectorAll('.bb-tabs-wrapper.scrollable');
    tabsWrapper.forEach(tab => {
        new BBTabs(tab).init();
    });
});