import {Collapse, Tab} from 'bootstrap';
import axios from 'axios';
import Cookie from 'js-cookie';
import {ajaxEndpoints, complianceMode, investorType, constants} from '../core/constant';
import Analytics from '../core/analytics';
import UTILS from '../core/utils';

export default class CountrySelector {
    constructor(eleSelector) {
        this.rootEle = `${eleSelector} .country-investor-selector`;
        this.selectors = {
            selectedCountry: `${this.rootEle} .selected-country > .selection`,
            tabLink: `${this.rootEle} .region-link`,
            countryItem: `${this.rootEle} .country-item`,
            investorType: `${this.rootEle} .investor-type`,
            cookiePolicyContent: `${this.rootEle} .cookie-policy-content`,
            termsConditionContent: `${this.rootEle} .terms-condition-content`,
            termsCondition: `${this.rootEle} .terms-condition`,
            acceptTermsCheckbox: `${this.rootEle} .acceptTermsCheckbox`,
            acceptTermsLabel: `${this.rootEle} .acceptTermsLabel`,
            warningMssg: `${this.rootEle} .warning-mssg`,
            warningTxtInstitutional: `${this.rootEle} .warning-txt-institutional`,
            warningTxtWholesale: `${this.rootEle} .warning-txt-wholesale`,
            tncMssg: `${this.rootEle} .tnc-mssg`,
            goToSiteCTA: `${this.rootEle} .goto-site-btn`,
            jpDomain: '#jpdomain'
		};
        this.userSelection = {};
        this.countryListContainer = [];
        this.countryClick = this.countryClick.bind(this);
        this.investoryClick = this.investoryClick.bind(this);
        this.tncCheckBoxClick = this.tncCheckBoxClick.bind(this);
        this.setCookieVal = this.setCookieVal.bind(this);
        document.querySelectorAll(`${this.rootEle} .country-list`).forEach((ele) => {
            this.countryListContainer.push(new Collapse(ele, {toggle:false}));
        });
    };


    countryClick(e) {
        const currentCountry = e.currentTarget;
        const countryID = currentCountry.dataset.countryId;
        const countryCode = currentCountry.dataset.countryCode;
        const regionCode = currentCountry.closest(".tab-pane").dataset.regionId;

        this.userSelection.countryName = currentCountry.dataset.countryName;
        this.userSelection.countryShortName = countryCode;
        this.userSelection.complianceMode = currentCountry.dataset.compliance;
        this.userSelection.redirectURL = currentCountry.dataset.redirectUrl;
		this.userSelection.fundLanguages = currentCountry.dataset.fundLanguages;
		this.userSelection.priipLanguages = currentCountry.dataset.priipLanguages;

        //Close country list
        this.countryListContainer.forEach((ele) => {
            ele.hide();
        })

        document.querySelectorAll(this.selectors.selectedCountry).forEach((selection) => {
            selection.innerHTML = currentCountry.innerHTML;
        });

        let event = new CustomEvent('country-changed', {detail:{country:countryID, region:regionCode, countryCode: countryCode}});
        document.querySelector(this.rootEle).dispatchEvent(event);
    };


    investoryClick(e) {
        let currentTypeClass = e.currentTarget.classList;

        if(currentTypeClass.contains('active') || currentTypeClass.contains('inactive')) {
            return;
        }

        document.querySelectorAll(this.selectors.investorType).forEach((type) => {
            type.classList.remove('active');
        });

        currentTypeClass.add('active');
        this.userSelection.investType = e.currentTarget.dataset.investType;

        let event = new CustomEvent('investor-changed', {detail:{investTye: e.currentTarget.dataset.investType}});
        document.querySelector(this.rootEle).dispatchEvent(event);
    };


    tncCheckBoxClick(e) {
        let gotTositeBtn = document.querySelectorAll(this.selectors.goToSiteCTA);
        let tncMssg = document.querySelectorAll(this.selectors.tncMssg);

        if(e.currentTarget.checked) {
            gotTositeBtn.forEach((btn) => {
                btn.classList.remove("disabled");
				btn.disabled=false;
            });
            tncMssg.forEach((tnc) => {
                tnc.classList.add("d-none");
            });
        } else {
            gotTositeBtn.forEach((btn) => {
                btn.classList.add("disabled");
				btn.disabled=true;
            });
            tncMssg.forEach((tnc) => {
                tnc.classList.remove("d-none");
            });
        }
    };

    updateInvestorCards(data) {
        if(data.country && data.country.length) {
            if(constants.disableInvestorType.includes(data.countryCode)) {
                this.userSelection.investType = '';
                document.querySelectorAll(this.selectors.investorType).forEach((type) => {
                    type.classList.remove('active');
                    type.classList.add('inactive');
                });
            } else {
                document.querySelectorAll(this.selectors.investorType).forEach((type) => {
                    type.classList.remove('inactive');
                });
            }
        }
    };


    enableTermsCheckbox(data) {
        document.querySelectorAll(this.selectors.acceptTermsCheckbox).forEach((checkbox) => {
            checkbox.disabled = false;
        });
        document.querySelectorAll(this.selectors.acceptTermsLabel).forEach((termsLabel) => {
            termsLabel.classList.remove('disabled');
        });
        document.querySelectorAll(this.selectors.warningMssg).forEach((warnMsg) => {
            warnMsg.classList.remove("d-none");
        });
    };


    disableTermsCheckbox() {
        document.querySelectorAll(this.selectors.acceptTermsCheckbox).forEach((checkbox) => {
            checkbox.disabled = true;
            if(checkbox.checked) {
                checkbox.checked = false;

                document.querySelectorAll(this.selectors.goToSiteCTA).forEach((btn) => {
                    btn.classList.add("disabled");
					btn.disabled=true;
                });

                document.querySelectorAll(this.selectors.tncMssg).forEach((tnc) => {
                    tnc.classList.remove("d-none");
                });
            }
        });

        document.querySelectorAll(this.selectors.acceptTermsLabel).forEach((termsLabel) => {
            termsLabel.classList.add('disabled');
        });

        document.querySelectorAll(this.selectors.warningMssg).forEach((warnMsg) => {
            warnMsg.classList.add("d-none");
        });
    };


    updateWarningMssg(data) {
        if(data && data.investTye) {
            let content = "";
            if(data.investTye == investorType.institutional) {
                content = document.querySelector(this.selectors.warningTxtInstitutional).value;
            }

            if(data.investTye == investorType.wholesale) {
                content = document.querySelector(this.selectors.warningTxtWholesale).value;
            }

            document.querySelectorAll(this.selectors.warningMssg).forEach((warnMsg) => {
                warnMsg.textContent = content;
            });

        } else {
            //empty out the warning mssg
            document.querySelectorAll(this.selectors.warningMssg).forEach((warnMsg) => {
                warnMsg.textContent = "";
            });
        }
    };


    async getCookieTnc(data) {
        var url = "";
        let endpoint = ajaxEndpoints.countryTnc;

        if(window.location.origin.indexOf("localhost") > -1) {
            url = ajaxEndpoints.origin;
        }
        endpoint = endpoint.replace("${regionid}", data.region);
        endpoint = endpoint.replace("${countryid}", data.country);
        url += endpoint;

        let response = await axios(url);

        document.querySelectorAll(this.selectors.cookiePolicyContent).forEach((cookiePolicy) => {
            cookiePolicy.innerHTML = response.data.CookiePolicy;
        });

        document.querySelectorAll(this.selectors.termsConditionContent).forEach((tncContent) => {
            tncContent.innerHTML = response.data.TermsAndConditions;
        });

        document.querySelectorAll(this.selectors.termsCondition).forEach((tnc) => {
            if(data.countryCode != constants.globalCountryCode) {
                tnc.classList.remove("d-none");
            } else {
                tnc.classList.add("d-none");
            }
        });
    };


    setStateFromObject(cookieObject) {
        let {countryCode, investType} = cookieObject;
        let countryItem = document.querySelector(`${this.selectors.countryItem}[data-country-code=${countryCode}]`);
        //select the tab of country get region id and use bootstrap to show it
        if(countryItem) {
            countryItem.dispatchEvent(new Event("click"));
            const currentTab = countryItem.closest('.tab-pane');
            if(currentTab) {
                const regionID = currentTab.dataset.regionId;
                let activeRegionLink = document.querySelectorAll(`${this.selectors.tabLink}[data-region-id="${regionID}"]`);
                if(activeRegionLink.length) {
                    activeRegionLink.forEach((link) => {
                        let tabPane = new Tab(link);
                        tabPane.show();
                    });
                }
            };
        }
        // Invest type selector.
        if(investType != undefined && investType.length > 0) {
            document.querySelector(`${this.selectors.investorType}[data-invest-type=${investType}]`).dispatchEvent(new Event("click"));
        }
    };

    trackUserSelection() {
        const {countryName,investType} = this.userSelection;
        let eventObj = Analytics.getAnalyticsObjectFor('siteoveraly');
        eventObj.investor_type = investType;
        eventObj.location = countryName;
        Analytics.pushToAnalytics(eventObj);
    }

    setCookieVal(e) {
        e.preventDefault();

        this.trackUserSelection();
        let jpDomain = document.querySelector(this.selectors.jpDomain);
        const {countryName,countryShortName,complianceMode,investType,redirectURL,fundLanguages,priipLanguages} = this.userSelection;
		let eventObj = Analytics.getAnalyticsObjectFor('showCookieBanner');


        //We need to pass query params when navigating from Japan to .COM(main site)
        //else if on main site and redirect url is present for a country (only valid for japan as of now) just do a plain redirect
        //to the given url if not present just reload in later case.
        //BLUEWEUS-350 update the fix
        //we need to set the cookie only if there is no redirect url.
        if(redirectURL.length > 0) {
            let url = redirectURL;
            if(jpDomain && jpDomain.value.toLowerCase() == 'true') {
                url = `${redirectURL}?set_country=${countryShortName}&set_investor=${investType}`;
            }
			eventObj.page_url = url;
			Analytics.pushToAnalytics(eventObj);
        } else {
            if(jpDomain && jpDomain.value.toLowerCase() == 'false') {
                Cookie.set(constants.countryCookie, `${countryName}|${countryShortName}|${complianceMode}|${investType}`, { expires: 365 });
                Cookie.set(constants.fundLanguagesCookie, `${fundLanguages}`, { expires: 365 });
                Cookie.set(constants.priipLanguagesCookie, `${priipLanguages}`, { expires: 365 });
            }

			eventObj.refresh_page = "true";
			Analytics.pushToAnalytics(eventObj);

        }
    };


    setCheckBoxState(data) {
        //Handle case where investor type is not needed for a country japan/global
        if(data && constants.disableInvestorType.includes(data.countryCode)) {
            this.enableTermsCheckbox();
            this.updateWarningMssg();
        } else {
            //Disable only if Investtype is not active
            const investorTypeCards = document.querySelectorAll(`${this.selectors.investorType}.active`);
            if(investorTypeCards.length == 0) {
                this.disableTermsCheckbox();
            }
        }
    };


    bindClickEvents() {
        document.querySelectorAll(this.selectors.countryItem).forEach((item) => {
            item.addEventListener("click", this.countryClick);
        });

        document.querySelectorAll(this.selectors.investorType).forEach((item) => {
            item.addEventListener("click", this.investoryClick);
        });

        document.querySelectorAll(this.selectors.acceptTermsCheckbox).forEach((checkbox) => {
            checkbox.addEventListener("click", this.tncCheckBoxClick);
        });

        document.querySelectorAll(this.selectors.goToSiteCTA).forEach((cta) => {
            cta.addEventListener("click", this.setCookieVal);
		});
    };


    bindChangeEvents() {
        document.querySelector(this.rootEle).addEventListener("country-changed", (e) => {
            this.updateInvestorCards(e.detail);
            this.getCookieTnc(e.detail);
            this.setCheckBoxState(e.detail);
        });

        document.querySelector(this.rootEle).addEventListener("investor-changed", (e) => {
            this.updateWarningMssg(e.detail);
            this.enableTermsCheckbox();
        });
    };

    async getCountryCodeByIP() {
        let self = this;

        if(!Cookie.get(constants.countryCookie)) {
            let response = await axios(ajaxEndpoints.countryCodeByIP);
            if(response.status == 200) {
                let isCountryPresent = document.querySelector(`${self.selectors.countryItem}[data-country-code='${response.data}']`);
                let countryCode = isCountryPresent ? response.data : constants.globalCountryCode;
                this.setStateFromObject({countryCode: countryCode});
            }
        }
    }

    init() {
        this.bindClickEvents();
        this.bindChangeEvents();
    }
}

let CSNavInstance = undefined;

if(document.getElementById('investorType')) {
    CSNavInstance = new CountrySelector('#investorType');
    CSNavInstance.init();

    let cookieObject = UTILS.getCookieObject();

    //cookie found pass it to country selector
    if(cookieObject.country && cookieObject.country.length) {
        CSNavInstance.setStateFromObject(cookieObject);
    }

}

export {CSNavInstance as CSNavInstance};
