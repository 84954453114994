// This class will mimic the state of UI components
// that are dependent on country cookie with out actual cookie being set

import UTILS from "../core/utils";
import {complianceMode, constants} from "../core/constant";
import {CSNavInstance} from './country-selector';
import MainNav from './header';

export default class SetUIState {
    constructor(stateObject = {}) {
        this.mainNav = new MainNav();
        this.stateObject = stateObject;
    }

    setState() {
        CSNavInstance.setStateFromObject(this.stateObject);
        this.mainNav.updateCountry(this.stateObject);
        this.mainNav.updateInvestor(this.stateObject);
    }
}

window.addEventListener('DOMContentLoaded', (event) => {
    let jpDomain = document.querySelector('#jpdomain');
    let countryCookie = UTILS.getCookieObject();
    if(jpDomain && jpDomain.value.toLowerCase() == 'true') {
        if(countryCookie.country == undefined) {
            const jpStateObject = {
                "country": constants.japanCountry,
                "countryCode": constants.japanCountryCode,
                "region": complianceMode.global,
                "investType": undefined
            };    
            new SetUIState(jpStateObject).setState();
        }
    }
});