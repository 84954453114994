import axios from 'axios';
import UTILS from '../core/utils';
import {complianceMode} from '../core/constant';
import Analytics from '../core/analytics';


class Bookmarks {
    constructor(element) {
        const {dataset} = element;
        this.element = element;
        this.insightList = element.querySelector('.insights-list');
        this.fundList= element.querySelector('.funds-list');
        this.noBookmarks = element.querySelector('.no-bookmarks');
        this.refreshInterval = (dataset.refreshInterval ? Number(dataset.refreshInterval) : 10) * 60 * 1000;
		this.deleteBookmarkLabel = (dataset.deleteBookmarkLabel);
        this.apiURL = dataset.apiUrl;
        this.maxQueueLength = dataset.maxQueueLength ? Number(dataset.maxQueueLength) : 6;
        this.bookMarkObject = {};
        this.bookMarkIDs = {
            insights: [],
            funds: []
        };
		// console.log(dataset);
    }

    async fetchBookMarks() {
        let response = await axios(this.apiURL, {
            params: {
                InsightsIds: this.bookMarkIDs.insights.join(","), 
                FundIds: this.bookMarkIDs.funds.join(","),
                countrycode: UTILS.getCookieObject().countryCode,
                CountryLanguage: UTILS.getCountryLanguage(UTILS.getCookieObject().countryCode),
                Compliance: UTILS.getCookieObject().region
            }
        });

        //update response data to add timestamp field for validity if zero de not refresh.
        response.data.expires = this.refreshInterval != 0 ? new Date().getTime() + this.refreshInterval : this.refreshInterval;
        localStorage.setItem('bookmarks', JSON.stringify(response.data));
    }

    deleteBookMarkItem(item) {
        const {type, id} = item.dataset;

        let bookmarkItem = item.closest('.bookmark-item');
        bookmarkItem.remove();

        //Check if the item removed is present on the page unbookmark it
        let pageBookmarkIcon = document.querySelector(`[data-type=${type}][data-id="${id}"]`);
        if(pageBookmarkIcon) {
            pageBookmarkIcon.classList.remove('is-bookmarked');
        }
    }

    updateBookmarkResponse() {
        localStorage.setItem('bookmarks', JSON.stringify(this.bookMarkObject));
    }

    removBookMarkID(item) {
        const itemID = Number(item.dataset.id);
        const bookMarkType = item.dataset.type;

        this.bookMarkIDs[bookMarkType] = this.bookMarkIDs[bookMarkType].filter((bookMarkID) => bookMarkID != itemID);
        this.bookMarkObject[bookMarkType] = this.bookMarkObject[bookMarkType].filter((item) => item.id != itemID);
    }

    getBookmarkMarkup(item, bookMarkType, deleteBookmarkLabel) {

        const  {id, tag, title, link, time, isVideoOrPodcast, url, findMoreText, author, category} = item;

        return `<div class="col-12 col-md-6 col-lg-4 bookmark-item">
        <div class="bb-card">
            <a href="#" class="cancel remove-bookmark body--16" aria-label="${deleteBookmarkLabel}" data-type="${bookMarkType}" data-id="${id}">
                <i class="far fa-times"></i>
            </a>    
            <div class="bb-card--caption">
                ${tag || category ? `<p class="bb-card--tag">${tag || category}</p>` : ``}

                <a href="${link || url}" class="bb-card--link" data-analytics='{"event":"view_bookmark","content_page_title":"${title}"}'>
                    <h4>${title}</h4>
                </a>
        
                <div class="bb-card--bottom-content">
                    ${time ?
                    `<a href="${link}" class="bb-card--link" >
                        ${isVideoOrPodcast ? `<i class="far fa-play-circle"></i>` : ``}
                        <span class='bb-card--time'>${time}</span>
                    </a>` : ``}
        
                    ${url ?
                        `<a href="${url}" class="bb-card--findmore" >
                            <span class='bb-card--find'>${findMoreText}<span class="icon fas fa-chevron-right"></span></span>
                        </a>` : ``}
                </div>
        
                ${author ? 
                `<div class="bb-author">
                    <div class="row">
                        <div class="col-12 ">
                            <div class="bb-author--picture">
                                <img src="${author.picture}" alt="${author.name}" class="rounded-circle" />
                            </div>
                            <div class="bb-author--details">
                                <div class="bb-author--name">${author.name}</div><div class="bb-author--date">${author.date}</div>
                            </div>
                        </div>
                    </div>
                </div>` : ``}
            </div>
         </div>
         </div>`;
    }

    updateUI() {
        const {insights, funds} = this.bookMarkIDs;
        if(insights.length == 0) {
            this.insightList.classList.add('d-none');
        }

        if(funds.length == 0) { 
            this.fundList.classList.add('d-none');
        }

        if(funds.length == 0 && insights.length == 0) { 
            this.noBookmarks.classList.remove('d-none');
        }
    }

    bindEventsOnNewUIElements() {
        const {insights, funds} = this.bookMarkIDs;
        if(insights.length || funds.length) {
          

            this.noBookmarks.classList.add('d-none');

            //Bind click event on ui change
            this.element.querySelectorAll('.remove-bookmark').forEach((item) => {
                item.addEventListener('click', (e) => {
                    e.preventDefault();
                    this.removBookMarkID(e.currentTarget);
                    this.updateBookmarkResponse();
                    this.deleteBookMarkItem(e.currentTarget);
                    this.updateUI();

                });
            });
        }
    }

    renderBookMarks() {
        const {insights, funds} = this.bookMarkObject
        const currentCompliance = UTILS.getCookieObject().region;

        //Remove all the existing nodes and regenerate them
        this.element.querySelectorAll('.bookmark-item').forEach((item) => item.remove());

        if(insights && insights.length) {
            insights.forEach((item) => {
                this.insightList.insertAdjacentHTML('beforeend', this.getBookmarkMarkup(item, 'insights', this.deleteBookmarkLabel));
            });
            this.insightList.classList.remove('d-none');
        } else {
            this.insightList.classList.add('d-none');
        }

        //Show funds only for regional country only
        if(funds && funds.length && currentCompliance == complianceMode.regional) {
            funds.forEach((item) => {
                this.fundList.insertAdjacentHTML('beforeend', this.getBookmarkMarkup(item, 'funds', this.deleteBookmarkLabel));
            });
            this.fundList.classList.remove('d-none');
        } else {
            this.fundList.classList.add('d-none');
        }
        Analytics.bindAnalyticEvents();

    }

    addBookmark(item, bookMarkCollection) {
        if(bookMarkCollection.length >= this.maxQueueLength) {
            bookMarkCollection.shift();
        }
        bookMarkCollection.push(Number(item.dataset.id));
    }

    bookmarkClicked(element) {
        let {classList, dataset} = element;
        
        if(classList.contains('is-bookmarked')) {
            let cardCancelIcon = this.element.querySelector(`[data-type="${dataset.type}"][data-id="${dataset.id}"]`);
            this.removBookMarkID(element);
            this.updateBookmarkResponse();
            this.deleteBookMarkItem(cardCancelIcon);
            this.updateUI();
        } else {
            this.addBookmark(element, this.bookMarkIDs[dataset.type]);

            this.fetchBookMarks().then(() => {
                this.createBookMarkIDObject();
                this.renderBookMarks();
                this.bindEventsOnNewUIElements();
                classList.add('is-bookmarked');
            });
        }

    }

    bindEvents() {
        const bookMarkIDs = this.bookMarkIDs;

        document.querySelectorAll('.bookmark-icon').forEach((item) => {
            //register click listener 
            item.addEventListener('click', (e) => {
                this.bookmarkClicked(e.currentTarget);                
            });

            //update bookmark icons
            let ID = Number(item.dataset.id);
            if(bookMarkIDs.insights.includes(ID) || bookMarkIDs.funds.includes(ID)) {
                item.classList.add('is-bookmarked');
            }
        });
    }

    checkBookMarks() {
        let bookMarkResponse = JSON.parse(localStorage.getItem('bookmarks'));
        let currentCompliance = UTILS.getCookieObject().region;

        if(bookMarkResponse) {
            const {expires} = bookMarkResponse;
            if(expires && new Date().getTime() > expires) {
                this.fetchBookMarks().then(() => {
                    this.createBookMarkIDObject();
                    this.renderBookMarks();
                    this.bindEventsOnNewUIElements();
                });
            } else {
                this.renderBookMarks();
                this.bindEventsOnNewUIElements();
            }
        } 
    }

    createBookMarkIDObject() {
        const bookMarkResponse = JSON.parse(localStorage.getItem('bookmarks'));
        if(bookMarkResponse) {
            //TODO: remove this after final api is ready from AD.
            this.bookMarkObject.insights = bookMarkResponse.insightslist || bookMarkResponse.insights;
            this.bookMarkObject.funds = bookMarkResponse.funds;
            this.bookMarkObject.expires = bookMarkResponse.expires;

            const {insights, funds} = this.bookMarkObject;

            this.bookMarkIDs.insights = insights && insights.length ? insights.map((item) => item.id) : [];
            this.bookMarkIDs.funds = funds && funds.length ? funds.map((item) => item.id) : [];
        }
    }

    init() {
        this.createBookMarkIDObject();
        this.checkBookMarks();
        this.bindEvents();
    }
}

window.addEventListener('DOMContentLoaded', (event) => { 
    const bookMarkWrapper = document.querySelector('.bb-bookmarks-wrapper');

    if(bookMarkWrapper) {
        const bookMarksInstance = new Bookmarks(bookMarkWrapper).init();
    }
});