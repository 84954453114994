const CONSTANTS = {
    ajaxEndpoints: {
        origin : "http://web-devrp.bluebay.msvc.cloud",
        siteCountryList : "/api/siteoverlay/countries",
        countryTnc: "/api/siteoverlay/tnccookiepolicy/${regionid}/${countryid}",
        countryCodeByIP : "/api/maxmind/countrycode"
    },
    complianceMode: {
        global: "Global",
        regional: "Regional",
        international: "International"
    },
    investorType: {
        institutional: "Institutional",
        wholesale: "Wholesale"
    },
    constants: {
        japanCountry: "Japan",
		japanCountryCode: "JP",
		countryCookie : 'bbcountry',
		fundLanguagesCookie : 'fund-languages',
		priipLanguagesCookie : 'priip-languages',
        cookieBanner : 'cookie-banner',
		flagPath: '/static/images/flags/ico-',
		flagExt: '.png',
        globalCountryCode: "GO",
        disableInvestorType: ["JP", "GO", "US", "CA"],
		citiCodeURLParam: "citicode",
        consentCookie : "consentCookie" 
    },

    carouselMetadata: {
        general:{
            selectors: {
                sliderWrapper: '.carousel-wrapper',
                slides: '.carousel-slide',
            },
            carouselConfig: {
                slidesPerView: 1,
                breakpoints: {
                    768: {
                      slidesPerView: 2
                    },
                    1024: {
                      slidesPerView: 3,
                    }
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }
            }
        }
    }
};

export const {ajaxEndpoints, complianceMode, investorType, constants, carouselMetadata} = CONSTANTS;
export default CONSTANTS;
