
window.addEventListener('DOMContentLoaded', (event) => {
	var mobileDisclaimer = document.querySelector('.bb-disclaimer-mobile');
    if(mobileDisclaimer) {
		var disclaimer = document.querySelector('.bb-disclaimer:not(.bb-disclaimer-mobile)');
		if(disclaimer) {
			mobileDisclaimer.querySelector('.bb-disclaimer--body').innerHTML =  disclaimer.querySelector('.bb-disclaimer--body').innerHTML;
		}
    }
});
