import BBDropDown from './bb-dropdown';
import BBPagination from './bb-pagination';
import UTILS from '../core/utils';
import Analytics from '../core/analytics';


import axios from 'axios';

class BBInsightsListing {
    constructor(element) {
        this.selectors = {
            filterWrapper: '.filter-wrapper',
            selectedFilter: '.selected-filter',
            selectedText: '.filter-text > span',
            insightsList: '.insights-list',
            podcastWrapper: '.break-container',
            pagination: '.bb-pagination',
            backToInsightsLink: '.selected-filter .link-arrow-left'
        };

        this.element = element;

        this.categoryDD = new BBDropDown(document.querySelector('#categoryDD'), {
            onChangeHandler: this.filterChanged.bind(this)
        });

        this.authorDD = new BBDropDown(document.querySelector('#authorDD'), {
            onChangeHandler: this.filterChanged.bind(this)
        });

        this.specialismDD = new BBDropDown(document.querySelector('#specialismDD'), {
            onChangeHandler: this.filterChanged.bind(this)
        });

        this.contentformatDD = new BBDropDown(document.querySelector('#contentformatDD'), {
            onChangeHandler: this.filterChanged.bind(this)
        });
        this.pagination = new BBPagination(this.element.querySelector(this.selectors.pagination), {
            onPageChangeHandler: this.pageChanged.bind(this)
        });

        this.init();
    }

    //create category list DD
    createCategoryDD() {
        let categoryDDList = this.element.querySelector('#categoryDD > .dropdown-menu');
        let categoryDDListItems = categoryDDList.querySelectorAll('li');
        let selectedValue = this.categoryDD.getSelectedValue();
 
        categoryDDListItems.forEach((item, index) => {
            if(index) {
                item.remove();
            }
        });
        this.categoryList.forEach((category) => {
            categoryDDList.insertAdjacentHTML('beforeend', `<li><a class="dropdown-item" data-value="${category.categories}" href="#">${category.specialism}</a></li>`);
        });
        
        this.categoryDD.init();
        let itemToMakeActive = this.categoryDD.getItemFromValue(selectedValue.value);
        if(itemToMakeActive.length) {
            this.categoryDD.makeItemActive(itemToMakeActive[0]);
        }
    }

    //create authors list DD
    createAuthorDD() {
        let authorDDList = this.element.querySelector('#authorDD > .dropdown-menu');
        let authorDDListItems = authorDDList.querySelectorAll('li');
        let selectedValue = this.authorDD.getSelectedValue();

        authorDDListItems.forEach((item, index) => {
            if(index) {
                item.remove();
            }
        });
        this.authorsList.forEach((author) => {
            authorDDList.insertAdjacentHTML('beforeend', `<li><a class="dropdown-item" data-value="${author.id}" href="#">${author.name}</a></li>`);
        });
        
        this.authorDD.init();
        let itemToMakeActive = this.authorDD.getItemFromValue(selectedValue.value);
        if(itemToMakeActive.length) {
            this.authorDD.makeItemActive(itemToMakeActive[0]);
        }
    }

    //create format list DD
    createContentFormatDD() {
        let contentformatDDList = this.element.querySelector('#contentformatDD > .dropdown-menu');
        let contentformatDDListItems = contentformatDDList.querySelectorAll('li');
        let selectedValue = this.contentformatDD.getSelectedValue();
 
        contentformatDDListItems.forEach((item, index) => {
            if(index) {
                item.remove();
            }
        });
        this.contentformatList.forEach((contentformat) => {
            contentformatDDList.insertAdjacentHTML('beforeend', `<li><a class="dropdown-item" data-value="${contentformat.id}" href="#">${contentformat.name}</a></li>`);
        });
        
        this.contentformatDD.init();
        let itemToMakeActive = this.contentformatDD.getItemFromValue(selectedValue.value);
        if(itemToMakeActive.length) {
            this.contentformatDD.makeItemActive(itemToMakeActive[0]);
        }
    }

    //set current listing state
    setListingState() {
        let {element} = this;
        this.listingState = {
            currentPage: this.pagination.paginationState.currentPage,
            pageSize: element.dataset.limit ? element.dataset.limit : 12,
            totalCount: 0,
            investType: UTILS.getCookieObject().investType,
            noResultMsg: "",
        };
    }

    //DropDown Change event handler
    filterChanged(e) {
        let content_topics = document.getElementById("categoryDD")
        let content_author = document.getElementById("authorDD")
        let content_specialism = document.getElementById("specialismDD")
        let content_contentformat = document.getElementById("contentformatDD")
        
        let topic =  content_topics.children[0].innerText
        let content = content_author.children[0].innerText
        let specialism =  content_specialism.children[0].innerText
        let contentformat = content_contentformat.children[0].innerText

        let eventObj = Analytics.getAnalyticsObjectFor('filterclick');

        eventObj['filter_author'] = content
        eventObj['filter_topics'] = topic
        eventObj['filter_asset_class'] = specialism
        eventObj['filter_format'] = contentformat
        eventObj['module_name'] = "Insights Listing";
        eventObj['number_of_article_dispayed'] = this.listingState.totalCount;
        Analytics.pushToAnalytics(eventObj);

        this.filterByTextUpdate();
                
        //2. Feth new insgihts as per drop down values
        this.getDataAndUpdateView().then(() => {
            //create authorDD on DD change
            this.createCategoryDD()
            this.createAuthorDD();
            this.createContentFormatDD();
        });
    }

    filterByTextUpdate() {
        if(this.element.querySelector(this.selectors.selectedFilter).dataset.isJapan.toLowerCase() === "true"){
            this.element.querySelector(this.selectors.selectedText).textContent = `${this.specialismDD.getSelectedValue().dispalyValue}, ${this.categoryDD.getSelectedValue().dispalyValue}, ${this.authorDD.getSelectedValue().dispalyValue}`;        
        } else {
            //update filter text values
            this.element.querySelector(this.selectors.selectedText).textContent = `${this.specialismDD.getSelectedValue().dispalyValue}, ${this.categoryDD.getSelectedValue().dispalyValue}, ${this.authorDD.getSelectedValue().dispalyValue}, ${this.contentformatDD.getSelectedValue().dispalyValue}`;
        }
    }

    //Page Change event handler
    pageChanged(e) {
        this.listingState.currentPage = this.pagination.paginationState.currentPage;
        this.fetchInsights().then(() => {
            this.updateListView();
            this.updateFilterView();
            this.scrollToListingView();
            this.filterByTextUpdate();
        });
    }

    //Update filter view we need to hide filters and show selected text instead of it
    updateFilterView() {
        const {currentPage} = this.listingState;
        const filterWrapper = this.element.querySelector(this.selectors.filterWrapper);
        const selectedFilter = this.element.querySelector(this.selectors.selectedFilter);

        //Show hide filter element depending on page
        if(currentPage > 1 && selectedFilter.classList.contains('d-none')) {
            selectedFilter.classList.remove('d-none');
            filterWrapper.classList.add('d-none');
        }

        if(currentPage == 1 && filterWrapper.classList.contains('d-none')) {
            selectedFilter.classList.add('d-none');
            filterWrapper.classList.remove('d-none');
        }
    }

    //Update the listing view to accomadate cards on each request or load
    //Take into account what page number it is and hide filters / Podcast module according
    updateListView() {

        const {currentPage, totalCount} = this.listingState;
        const podcastWrapperElement = this.element.querySelector(this.selectors.podcastWrapper);
        const insightsWrapperElement =this.element.querySelector(this.selectors.insightsList);


        //Show hide podcast element depending on page
        if(currentPage > 1 && podcastWrapperElement && !podcastWrapperElement.classList.contains('d-none')) {
            podcastWrapperElement.classList.add('d-none');
        }

        if(currentPage == 1 && podcastWrapperElement && podcastWrapperElement.classList.contains('d-none')) {
            podcastWrapperElement.classList.remove('d-none');
        }

        // Apply spacing class on the break-container to manage spacing w.r.t to totalCount
        if(podcastWrapperElement) {
            let podcastClasslist = podcastWrapperElement.classList;
            totalCount == 0 ? podcastClasslist.add('no-spacing') : totalCount <= 6 ? podcastClasslist.add('no-bottom-spacing') : podcastClasslist.remove('no-spacing', 'no-bottom-spacing');
        }

        //Remove existing cards if any from the wrapper
        insightsWrapperElement.querySelectorAll('.insights-item').forEach(item => {
            item.remove();
        });

        if(totalCount == 0) {
            insightsWrapperElement.insertAdjacentHTML('afterbegin', this.getNoResultMarkup());
            return;
        }

        //Add cards to list
        this.listingState.insightslist.forEach((item, index) => {
           
            if(podcastWrapperElement && this.listingState.currentPage == 1 && index < 6 ) {
                podcastWrapperElement.insertAdjacentHTML('beforebegin', this.generateCardMarkup(item));
            } else {
                insightsWrapperElement.insertAdjacentHTML('beforeend', this.generateCardMarkup(item));

            }
            Analytics.bindAnalyticEvents();
        });
    }

    //Scroll to top of listing component
    scrollToListingView() {
        window.scrollTo({
            top: this.element.closest('.bb-theme').offsetTop - 30,
            left: 0,
            behavior: 'auto'
        });
    }
    
    //Take the current total count and form the elements
    renderPagination() {
        let paginationInstance = this.pagination
        paginationInstance.paginationState.totalItemsCount = this.listingState.totalCount;
        paginationInstance.paginationState.pageSize = this.listingState.pageSize;
        paginationInstance.paginationState.pageCount = Math.ceil(this.listingState.totalCount / this.listingState.pageSize);
        // show if page count is greater then 1
        if(paginationInstance.paginationState.pageCount > 1) {
            paginationInstance.render();
        } else {
            paginationInstance.hide();
        }
    }
    
    //Generate the no Result call out text markup
    getNoResultMarkup() {
        return `
            <div class="col-12 insights-item card-item">
                <p class="quote color--dark-blue-tint-1">${this.listingState.noResultMsg}<p>
            </div>
        `;
    }
    
    //Generate insight car markup given an data object with fields
    generateCardMarkup(item) {
            
        let {author, defaultImage, isVideoOrPodcast, link, small, tag, time, title, medium, xlarge , gtmscript,specialism,contentformat} = item;
        let cardMarkup = `
        <div class="col-12 col-md-6 col-lg-4 insights-item card-item">
        <div class="bb-card">
        ${defaultImage ? `
            ${link ? `<a href="${link}" class="bb-card--link" data-analytics='${gtmscript}'>` : ``}

            <div class="bb-card--image">
                <picture class="bb-card--photo">
                    ${xlarge ? `<source srcset="${xlarge}" media="(min-width: 1200px)" />` : ''}
                    ${medium ? `<source srcset="${medium}" media="(min-width: 768px)" />` : ''}
                    ${small ? `<source srcset="${small}" media="(min-width: 576px)"/>` : ''}
                    <img src="${defaultImage ? defaultImage : 'http://via.placeholder.com/350x205"'}" alt="${title}" >
                </picture>
                ${isVideoOrPodcast ? `<div class="bb-card--play-icon"><i class="fas fa-play"></i></div>` : ''}
            </div>
            ${link ? `</a>` : ``}
            `
        : ""}           
        <div class="bb-card--caption">
            ${tag ? `<p class="bb-card--tag">${tag}</p>`: ''}
    
            ${link ? `<a href="${link}" class="bb-card--link"><h4>${title}</h4></a>` : `<h4>${title}</h4>`}

            <div class="bb-card--bottom-content">
                ${time ? `<a href="${link}" class="bb-card--link" >
                    ${isVideoOrPodcast ? `<i class="far fa-play-circle"></i>` : ''}
                    <span class='bb-card--time'>${time}</span>
                </a>` : ''}
            </div>
            ${author.name ? 
                `<div class="bb-author">
                    <div class="row">
                        <div class="col-12 ">
                            <div class="bb-author--picture">
                                ${author.picture ? `
                                <img src="${author.picture}" alt="${author.name}" class="rounded-circle" />` : ""}
                            </div>
                            <div class="bb-author--details">
                                <div class="bb-author--name">${author.name}</div><div class="bb-author--date">${author.date}</div>
                            </div>
                        </div>
                    </div>
                </div>` 
                : ``}
        </div>
        </div>
        </div>`;

        return cardMarkup;
    }

    getDataAndUpdateView() {
        return this.fetchInsights().then(() => {
            this.updateListView();
            this.renderPagination();
        }).catch(err => { console.log("error in request", err) });
    }

    async fetchInsights() {
        
        let category = this.categoryDD.getSelectedValue().value;
        let author = this.authorDD.getSelectedValue().value;
        let specialism = this.specialismDD.getSelectedValue().value;
        let contentformat = this.contentformatDD.getSelectedValue().value;
        category = category.length == 0 || category.toLowerCase() == "all" ? "" : category;
        author = author.length == 0 || author.toLowerCase() == "all" ? "" : author;
        specialism = specialism.length == 0 || specialism.toLowerCase() == "all" ? "" : specialism;
        contentformat = contentformat.length == 0 || contentformat.toLowerCase() == "all" ? "" : contentformat;

        let response = await axios(this.element.dataset.apiUrl, {
            params: {
                page: this.listingState.currentPage,
                categories: category, 
                author: author,
                specialism:specialism,
                contentformat:contentformat,
                mode: this.listingState.investType ? this.listingState.investType : ""
            }
        });
        
        this.listingState.insightslist = response.data.insightslist;
        
        
        this.listingState.totalCount = parseInt(response.data.totalCount);
        this.listingState.noResultMsg = response.data.message ? response.data.message : '';
        this.authorsList = response.data.authors;
        this.categoryList = response.data.categories;
        this.contentformatList = response.data.formats;
        this.specialism= response.data.specialism;
    }

    resetFilters() {
        this.categoryDD.setValue("all");
        this.authorDD.setValue("all");
        this.specialismDD.setValue("all");
        this.contentformatDD.setValue("all");
    }

    //Reset listng state 
    resetListingState() {
        this.listingState.currentPage = 1;
        this.pagination.paginationState.currentPage = 1;
        this.resetFilters();
        this.updateFilterView();
    }

    //check if dropdown pen 

    isDDOpen () {

        let ddElement = document.querySelectorAll(".bb-insights-list-wrapper .dropdown-toggle");

        ddElement.forEach(data=> {
            data.addEventListener('click' , () => {
                let isHidden = data.getAttribute("aria-expanded");
        
                isHidden === 'true' ?  window.scrollTo({
                    top: this.element.closest('.bb-theme').offsetTop - 30,
                    left: 0,
                    behavior: 'auto'
                }) : ''
            })
        })
    }

    bindEvents() {
        //Back to all insights link handler
        this.element.querySelector(this.selectors.backToInsightsLink).addEventListener('click', (e) => {
            e.preventDefault();
            this.resetListingState()
        });
    }

    init() {
        this.bindEvents();
        this.setListingState();
        this.isDDOpen();
        this.getDataAndUpdateView().then(() => {
            // create authorDD on intial load as well and override the
            // AD markup that was generated
            this.createCategoryDD();
            this.createAuthorDD();
            this.createContentFormatDD();
        });
    }
}


window.addEventListener('DOMContentLoaded', (event) => {
    let insightsListWrapper = document.querySelector('.bb-insights-list-wrapper');
    if(insightsListWrapper) {    
        new BBInsightsListing(insightsListWrapper);
    }
});
