import {Modal} from 'bootstrap';
import UTILS from '../core/utils';
import CountrySelector from './country-selector';
import Analytics from '../core/analytics';


window.addEventListener('DOMContentLoaded', (event) => {
    const siteEntryModal = document.getElementById('siteEntryModal');
    const isInternalUser = document.querySelector('body').dataset.isinternaluser;
   if(isInternalUser === "true" || isInternalUser === true){
       window.dataLayer.push({
           "event": "IP_detected",
           "user_type" : "internal"
       })
   };

    class SiteEntry {
        constructor() {
            this.countrySelector = new CountrySelector('#siteEntryModal');
            this.countrySelector.init();

        }

        init() {
            let cookieObject = UTILS.getCookieObject();
            this.countrySelector.getCountryCodeByIP();
            const myModal = new Modal(siteEntryModal, {backdrop: 'static', keyboard: false});
            myModal.show();
        }
    }

    if(siteEntryModal && siteEntryModal.dataset.showsiteentryoverlay == "true" && UTILS.getCookieObject().country == undefined) {
        const siteEntry = new SiteEntry();
        siteEntry.init();
    } else {
		let eventObj = Analytics.getAnalyticsObjectFor('showCookieBanner');
        eventObj.refresh_page = "false";
        Analytics.pushToAnalytics(eventObj);
	}
});
