import Cookie from 'js-cookie';
import { investorType, constants} from '../core/constant';
import BBDropDown from './bb-dropdown';
import UTILS from '../core/utils';
import axios from 'axios';


class BBFundDetail {
    constructor(element, config) {
		this.element = element;

        this.selectors = {
			fundInfoFrame : "fund-info-frame", // ID of the iframe element
			shareClassDD: "shareClassDD",  // ID of share class Dropdown
			dropdownMenu: '.dropdown-menu', // class for Share class dropdown menu
			keyDocuments: '.bb-key-documents ul',
			ISIN: '.bb-ISIN',
			fundInfoSection: '.fund-info',
			keyDocumentSection: '.bb-key-documents',
			shareClassfilterSection: '.bb-share-class-filter'
        };

		// details from Global Config
		this.baseURL =  config.baseURL;
		this.PRIIPAPIURL =  config.PRIIPAPIURL;
		this.factSheetURL =  config.factSheetURL;
		this.citiCodeParam = 'citiCode'
		this.citiCode = config.citiCode;
		this.fundCodeParam = 'fundcodes'
		this.fundCode = config.fundCode;
		this.keyDocumentsMap = config.keyDocumentsMap;
		this.languageMap = config.languageMap;
		this.hideFEFundInfoData = config.hideFEFundInfoData;

		// Details from Cookies
		this.country = UTILS.getCookieObject();
		this.languagesParam = 'languages'
		this.languages = Cookie.get(constants.fundLanguagesCookie);
		this.priiplanguages = Cookie.get(constants.priipLanguagesCookie);
		this.regionParam = 'regions'
		this.region = this.country.countryCode;
		this.investType = this.country.investType;

		this.shareClasses;
		this.currentShareClass;
		this.shareClassDD;

        this.init();
    }

	setCitiCode() {
		// override default citi code from URL if exist
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		var URLCitiCode =  urlParams.get(constants.citiCodeURLParam);

		if(URLCitiCode) {
			this.citiCode = URLCitiCode
		}
	}

	getFrameURL() {
		return this.factSheetURL + "?"+ this.citiCodeParam + "=" + this.citiCode;
	}

	updateFactSheetFrame() {
		var frame = document.getElementById(this.selectors.fundInfoFrame);
		frame.contentWindow.location.replace(this.getFrameURL());

	}

	updateISIN() {		
		this.element.querySelector(this.selectors.ISIN).innerHTML = this.currentShareClass.ISIN;
	}

	clearISIN() {
		this.element.querySelector(this.selectors.ISIN).innerHTML = '';
	}

	updateURLCitiCode() {
		const url = new URL(window.location);

		let currentURLCiti = url.searchParams.get(constants.citiCodeURLParam);

		if(currentURLCiti !== this.citiCode) {
			url.searchParams.set(constants.citiCodeURLParam, this.citiCode);

			window.history.pushState(null, null, url);
		}
	}

	setCurrentShareClass() {
		this.shareClasses.forEach(item => {
			if (item.Citicode === this.citiCode) {				
				this.currentShareClass = item;

                // const langArr = this.languages.split(",");
				if(this.priiplanguages!==""){
					const priiplanguages = this.priiplanguages.split(",");
					const PRIIPURLs = priiplanguages.map(el => {
						const countryLanguage = `${this.country.countryCode}-${el}`;
						const langURL = `${this.PRIIPAPIURL}&shareClass=${this.currentShareClass.ISIN}&documentType=PRP&country=${this.country.countryCode}&language=${el.slice(0, 2)}`;
						return {
							[countryLanguage]: langURL
						};
					});
					const combinedObject = Object.assign({}, ...PRIIPURLs);
					this.currentShareClass.PRIIPURL = combinedObject
				}				                             
            }
		});
	}

	getLanguageName(langCode) {
		let langName = langCode;
			for (var lang of Object.keys(this.languageMap)) {
				if (lang === langCode){
					langName = this.languageMap[lang]
				}
			}
		return langName
	}

	getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');

        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

	updateKeyDocuments() {
		if(this.currentShareClass) {
			let docHTML = ""
			docHTML += `<div class="accordion accordion-wrapper keydoc-accordion" id="keydocument">`
			let countryCode = this.getCookie('bbcountry').split('|')[1];
			for (var docType of Object.keys(this.keyDocumentsMap)) {
				if((docType === 'KIIDocumentURL' && countryCode.toUpperCase() !== "GB") || (docType === 'PRIIPURL' && countryCode.toUpperCase() === "GB")){
					continue;
				}

				let docArr = this.currentShareClass[docType] || {};

				if(Object.keys(docArr).length > 1) {
					docHTML += `
								<div class="accordion-item">
									<h2 class="accordion-header">
									<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#${this.keyDocumentsMap[docType].replace(/ /g,'')}" aria-expanded="true" aria-controls="${this.keyDocumentsMap[docType].replace(/ /g,'')}">
										${this.keyDocumentsMap[docType]}
									</button>
									</h2>
									<div id="${this.keyDocumentsMap[docType].replace(/ /g,'')}" class="accordion-collapse collapse" aria-labelledby="${this.keyDocumentsMap[docType].replace(/ /g,'')}" data-bs-parent="#keydocument">
										<div class="accordion-body">
										<ul>
											`
										for (var doc of Object.keys(docArr)) {
											if (
												docArr[doc] &&
												docArr[doc] != "" &&
												docArr[doc] != "-" &&
												doc.length >= 3
											) {

												let language = this.getLanguageName(doc.substr(3, 2))
												docHTML += `<li><a href="${docArr[doc]}" target="_blank">${language}</a></li>`
											}
										}

										`</ul>`
										docHTML += ` </div></div></div>`;
				}
				else {
					for (var doc of Object.keys(docArr)) {
						if(docArr[doc] && docArr[doc] != "" && docArr[doc] != "-" && doc.length >= 3) {

							docHTML += `<li><a href="${docArr[doc]}" target="_blank">${this.keyDocumentsMap[docType]} (${doc.substr(3, 2)}) </a></li>`;
						}
					}
				}
			}
			docHTML +=  `</div>`;
			this.element.querySelector(this.selectors.keyDocuments).innerHTML = docHTML;
		}
		//add active class to first element
		let defaultAccoordionshow = document.querySelector(".accordion-collapse.collapse")
		if(defaultAccoordionshow) defaultAccoordionshow.classList.add("show")
	}



	shareClassDDChanged() {
		this.citiCode = this.shareClassDD.getSelectedValue().value;
		if(this.citiCode.trim().length) {
			this.showIframeAndKeyDocSection();
			this.setCurrentShareClass();
			this.updateISIN();
			this.updateFactSheetFrame();
			this.updateKeyDocuments();
			this.updateURLCitiCode();
		} else {
			this.hideIframeAndKeyDocSection();
			this.clearISIN();
		}
	}

	showIframeAndKeyDocSection() {
		document.querySelector(this.selectors.fundInfoSection).classList.remove('d-none');
		document.querySelector(this.selectors.keyDocumentSection).classList.remove('d-none');
	}

	hideIframeAndKeyDocSection() {
		document.querySelector(this.selectors.fundInfoSection).classList.add('d-none');
		document.querySelector(this.selectors.keyDocumentSection).classList.add('d-none');
	}

	// Note: filter for wholeshale is now removed in phase 1.2 and
	// we don't have any clarity as of this time how we need to filter out
	// May be something for fututer implementation
	// filterShareListForWholesale(shareClassList) {
	// 	if (this.investType == investorType.wholesale) {
	// 		shareClassList = shareClassList.filter(item => item.InstitutionalOnly.toLowerCase() != 'true');
	// 	}

	// 	return shareClassList;
	// }

	filterShareListForCountry(shareClassList) {
		const {country} = UTILS.getCookieObject();
		shareClassList = shareClassList.filter(item => item.RegisteredForSaleIn.indexOf(country) > -1);
		return shareClassList;
	}

	sortShareListByName(shareClassList) {
		if(shareClassList.length) {
			shareClassList.sort((a, b) => a.UnitNameShort > b.UnitNameShort ? 1 : -1);
		}
		return shareClassList;
	}

	updateShareClassDD(shareClassList) {
		let shareClassDD = document.getElementById(this.selectors.shareClassDD);
		let {selectText} = shareClassDD.dataset;
		let shareClassDDMenu = shareClassDD.querySelector(this.selectors.dropdownMenu);
		let DDListHtml = "";

		shareClassList.forEach(item => {

			if(item.Citicode === this.citiCode) {
				this.currentShareClass = item;
			}

			DDListHtml += `<li><a class="dropdown-item "
							data-value="${item.Citicode}"
							data-primary-citi-code=${item.PrimaryCiticode}
							data-isin="${item.ISIN}"
							data-fund-code="${item.FundCode}" href="#">${item.UnitNameShort}</a></li>`;
		});

		if(DDListHtml) {
			shareClassDDMenu.innerHTML = DDListHtml;
		}

		if(this.currentShareClass == undefined) {
			let selectOptionItem = `<li><a class="dropdown-item " data-value=" " href="#">${selectText}</a></li>`;
			shareClassDDMenu.insertAdjacentHTML('afterbegin', selectOptionItem);
		}

		// Bind on change event as we now have added Valued to dropdown
		this.shareClassDD = new BBDropDown(shareClassDD, {
            onChangeHandler: this.shareClassDDChanged.bind(this)
        });

		// Set Active to trigger on load Change even and update iframe,key documents and ISIN
		if(this.currentShareClass) {
			this.shareClassDD.setValue(this.currentShareClass.Citicode);
		} else {
			this.shareClassDD.setValue(' ');
		}
	}

	// Update this to utilize session storage
	async getFundDetails() {
		let URL = this.baseURL;
		let sessionKey = this.fundCode + "_" + this.country.countryCode + "_" + this.investType;

		let sessionString = UTILS.getSessionStorage(sessionKey);

		if(sessionString) {
			let sessionData = JSON.parse(sessionString);
			this.shareClasses = sessionData;			
			return  sessionData;
		}

		if(URL) {
			URL += "&" + this.languagesParam + "=" + this.languages;
			URL += "&" + this.regionParam + "=" + this.region;
			URL += "&" + this.fundCodeParam + "=" + this.fundCode;

			let response = await axios(URL);

			this.shareClasses = this.sortShareListByName(this.filterShareListForCountry(response.data));
			UTILS.setSessionStorage(sessionKey, JSON.stringify(this.shareClasses));

			return this.shareClasses;
		}
	}

	hideShareClassDD() {
		document.querySelector(this.selectors.shareClassfilterSection).classList.add('hide-filter');
	}

	//Fund can be expilicty disabled if author sets
	// hideFEFundInfoData : true,
	isFEFundInfoDisabled() {
		return this.hideFEFundInfoData == "true";
	}

    init() {
		this.setCitiCode();

		if(this.isFEFundInfoDisabled()) {
			this.hideShareClassDD();
			return;
		}

		this.getFundDetails().then((response) => {
			if(response.length) {
				this.updateShareClassDD(response);
			} else {
				this.hideShareClassDD();
			}
        });



    }
}

window.addEventListener('DOMContentLoaded', (event) => {
    const fundsDetailPage = document.querySelector('.funds-detail-page');
	const fundDetailConfig = window.BB && window.BB.fundDetailConfig;

    if(fundsDetailPage && fundDetailConfig) {
        new BBFundDetail(fundsDetailPage, fundDetailConfig);
    }
});


// window.addEventListener('popstate', (event) => {

// 	console.log('2')
// 	var state = event.originalEvent.state;
// 	console.log(state, 'state')

// 	const fundsDetailPage = document.querySelector('.funds-detail-page');
// 	const fundDetailConfig = window.BB && window.BB.fundDetailConfig;

// 	if(fundsDetailPage && fundDetailConfig) {
// 		new BBFundDetail(fundsDetailPage, fundDetailConfig);
// 	}

// });


